import React, { useEffect, useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';

import { Link } from 'react-router-dom';
import StyledParagraph16 from '../Shared/StyledParagraph16';
import { storage } from '../../Firebase';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useIsMobile } from '../../Hooks';

const BlogCard = ({ data, onClick }) => {
	const mobile = useIsMobile();
	const [url, setUrl] = useState(null);

	useEffect(() => {
		data &&
			getDownloadURL(ref(storage, `Blogs/${data?.img}`))
				.then((url) => {
					setUrl(url);
				})
				.catch((error) => {
					console.log(error.msg);
				})
				.finally(() => {});
	}, [data]);

	const handleClick = () => {
		onClick && onClick();
	};
	return (
		<Container mobile={mobile}>
			<Img width='380' src={`${url}`} alt='' />

			<TextContainer>
				<StyledParagraph16
					className='title'
					lineHeight='29px'
					fontSize='24px'
					fontSizeMobile='20px'
					margin='0 0 8px 0'
					marginMobile='0 0 8px 0'>
					{data?.title?.length > 84 ? data?.title?.slice(0, 84) : data?.title}
					{data?.title?.length > 84 && '...'}
				</StyledParagraph16>
				<StyledParagraph16 margin='0' marginMobile='0'>
					{data?.body?.length > 156 ? data?.body?.slice(0, 156) : data?.body}
					{data?.body?.length > 156 && '...'}
				</StyledParagraph16>
			</TextContainer>
			<Link
				to={`/blogs/detalle/${data?.blogId}`}
				className='blogLink'
				onClick={handleClick}>
				Leer nota completa
			</Link>
		</Container>
	);
};

export default BlogCard;

const Container = styled.div`
	width: ${({ mobile }) => (mobile ? '328px' : '373px')};
	height: 504px;
	box-sizing: border-box;
	background: ${theme.colors.white};
	border: 2px solid ${theme.colors.blogGrey};
	border-radius: 1.5rem;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.blogLink {
		text-decoration: none;
		font-family: helvBlack;
		color: ${theme.colors.primary};
		width: 90%;
		margin: 0 auto 1.5rem auto;
	}

	:hover {
		box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.2);
	}
`;

const TextContainer = styled.div`
	width: 90%;
	max-height: 216px;
	margin: 1.5rem auto 0 auto;
	flex: 1 1;

	.title {
		overflow: hidden;
		word-break: break-word;
	}
`;

const Img = styled.img`
	height: ${({ mobile }) => (mobile ? '190px' : '42%')};
	min-height: ${({ mobile }) => (mobile ? '190px' : '180px')};
	width: 100%;
	overflow: hidden;
	object-fit: cover;
	object-position: center;
`;
