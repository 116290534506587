import React from 'react';
import styled from 'styled-components';
import theme from '../../Constants/theme';
import { useIsMobile } from '../../Hooks/index';

const Location = () => {
	const mobile = useIsMobile();
	return (
		<Container mobile={mobile}>
			<iframe
				title='Mapa'
				src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.043162973101!2d-64.17675988431141!3d-31.467998805900255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432a31689bd0c1b%3A0x97006feb2b3b5604!2sSimple%20Abasto%20Gastron%C3%B3mico!5e0!3m2!1ses-419!2sar!4v1665524985953!5m2!1ses-419!2sar'
				style={{ border: 0 }}
				allowFullScreen=''
				loading='lazy'
				referrerPolicy='no-referrer-when-downgrade'
			/>
		</Container>
	);
};

export default Location;

const Container = styled.div`
	width: ${({ mobile }) => (mobile ? '100%' : '82.3%')};
	margin: ${({ mobile }) =>
		mobile
			? `0 auto ${theme.marginVertical.mobile.nosotrosComponent} auto`
			: `0 auto ${theme.marginVertical.desktop.nosotrosComponent} auto`};
	border-radius: 32px;
	overflow: hidden;

	iframe {
		width: 100%;
		height: ${({ mobile }) => (mobile ? '434px' : '388px')};
	}
`;
