import { IoEllipse } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import React from 'react';
import StyledParagraph18 from './../Shared/StyledParagraph18/index';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useIsMobile } from '../../Hooks/index';

const CategoryCard = ({ data, onClick }) => {
	const { name, href } = data;
	const mobile = useIsMobile();
	const handleClick = () => {
		onClick();
	};
	return (
		<Container mobile={mobile}>
			<Link className='link' onClick={handleClick} to={`/blogs/${href}`}>
				<IoEllipse color={`${theme.colors.primary}`} />
				<StyledParagraph18
					className='label'
					align='center'
					alignMobile='center'
					fontFamily='helvMedium'
					margin='0'
					marginMobile='0'>
					{name}
				</StyledParagraph18>
			</Link>
		</Container>
	);
};

export default CategoryCard;
const Container = styled.div`
	display: flex;
	align-items: center;
	padding: ${({ mobile }) => (mobile ? '13px 16px' : '15px 16px')};
	width: ${({ mobile }) => (mobile ? '242px' : '272px')};
	background-color: ${theme.colors.white};
	border: 1px solid ${theme.colors.blogCategoryGrey};
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
	border-radius: 8px;
	.label {
		flex: 1 1;
	}
	:hover {
		background-color: ${theme.colors.blogCategoryGrey};
	}

	.link {
		display: flex;
		width: 100%;
		justify-content: space-evenly;
		align-items: center;
		text-decoration: none;
	}
`;
