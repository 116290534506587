import React, { useEffect, useState } from 'react';
import { RiDeleteBin2Line, RiEditLine } from 'react-icons/ri';
import { deleteDoc, doc } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';

import { Link } from 'react-router-dom';
import PrimaryButton from '../../../Shared/PrimaryButton';
import StyledParagraph16 from '../../../Shared/StyledParagraph16';
import { db } from '../../../../Firebase';
import { storage } from '../../../../Firebase';
import styled from 'styled-components';
import theme from './../../../../Constants/theme';

const BlogItem = ({ data }) => {
	const { img, title, body, blogId, id } = data;
	const [imgUrl, setImgUrl] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		const reference = ref(storage, `Blogs/${img}`);
		getDownloadURL(reference).then((url) => setImgUrl(url));
	}, [img]);

	const handleToggle = () => {
		setModalOpen(!modalOpen);
	};

	const handleDelete = (id) => {
		handleToggle();
		deleteDoc(doc(db, 'Blogs', id));
	};

	return (
		<Container>
			<Img width='120' src={imgUrl} alt='' />
			<StyledParagraph16
				fontFamily='helvBlack'
				className='title'
				width='20%'
				margin='0'
				align='center'>
				{title}
			</StyledParagraph16>
			<StyledParagraph16 className='body' width='68%' margin='0'>
				{body}
			</StyledParagraph16>
			<Link to={`/admin/blogs/editar-blog/${blogId}`}>
				<RiEditLine
					className='icon'
					size={30}
					color={`${theme.colors.negro80}`}
				/>
			</Link>
			<RiDeleteBin2Line
				className='icon'
				size={32}
				color={`${theme.colors.negro80}`}
				onClick={handleToggle}
			/>
			{modalOpen && (
				<Modal>
					<StyledParagraph16
						margin='0 0 10px 0'
						color={`${theme.colors.white}`}>
						Confirma que desea eliminar?
					</StyledParagraph16>
					<div>
						<PrimaryButton
							colorHover={`${theme.colors.white}`}
							background={`${theme.colors.white}`}
							color={`${theme.colors.primary}`}
							padding='8px 10px'
							margin='0 5px'
							onClick={() => handleDelete(id)}>
							Si
						</PrimaryButton>
						<PrimaryButton
							colorHover={`${theme.colors.white}`}
							background={`${theme.colors.white}`}
							color={`${theme.colors.primary}`}
							padding='8px 10px'
							margin='0 5px'
							onClick={handleToggle}>
							No
						</PrimaryButton>
					</div>
				</Modal>
			)}
		</Container>
	);
};

export default BlogItem;
const Container = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	column-gap: 2rem;
	border: 1px solid ${theme.colors.grey};
	padding: 1% 2%;
	border-radius: 5px;
	:hover {
		box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.2);
	}

	.icon:hover {
		color: ${theme.colors.primary} !important;
	}

	.body,
	.title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 1%;
	}
`;
const Img = styled.img`
	height: 60px;
	object-fit: cover;
	object-position: center;
	z-index: ${theme.zIndex.blogItem};
`;

const Modal = styled.div`
	width: 300px;
	right: 0;
	top: 85px;
	padding: 10px;
	z-index: ${theme.zIndex.modalBlogItem};
	margin: 0 0 0 0;
	background-color: ${theme.colors.primary};
	border-radius: 5px;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
