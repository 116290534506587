import React from 'react';
import StyledH1 from '../Shared/StyledH1';
import StyledH3 from './../Shared/StyledH3/index';
import { categories } from './../../Utils/index';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useIsMobile } from '../../Hooks/index';

const NuestrosProductos = () => {
	const mobile = useIsMobile();
	const div1 = categories.slice(0, 3);
	const div2 = categories.slice(3);

	const handleClick = () => {
		const link = document.createElement('a');
		link.href =
			'https://api.whatsapp.com/send?phone=5493518170151&text=%C2%A1Hola!%20Necesito%20m%C3%A1s%20informaci%C3%B3n%20acerca%20de%20sus%20productos%20y%20precios.';
		link.setAttribute('target', '_blank');
		link.click();
	};
	return (
		<Container mobile={mobile}>
			<StyledH1
				id='Productos'
				align='center'
				alignMobile='center'
				margin={`${theme.marginVertical.desktop.h1}`}
				marginMobile={`${theme.marginVertical.mobile.h1}`}>
				Nuestros productos
			</StyledH1>
			<ProductosContainer mobile={mobile}>
				<Group1 mobile={mobile}>
					{div1.map((elem, i) => (
						<Category key={i} background={elem.srcBackground}>
							<StyledH3 color={`${theme.colors.white}`}>{elem.name}</StyledH3>
							<StyledH3
								onClick={handleClick}
								fontSize='16px'
								color={`${theme.colors.white}`}>
								Más información
							</StyledH3>
						</Category>
					))}
				</Group1>
				<Group2 mobile={mobile}>
					{div2.map((elem, i) => (
						<Category key={i} background={elem.srcBackground}>
							<StyledH3 color={`${theme.colors.white}`}>{elem.name}</StyledH3>
							<StyledH3
								onClick={handleClick}
								fontSize='16px'
								color={`${theme.colors.white}`}>
								Más información
							</StyledH3>
						</Category>
					))}
				</Group2>
			</ProductosContainer>
		</Container>
	);
};

export default NuestrosProductos;
const Container = styled.div`
	width: ${({ mobile }) => (mobile ? '91%' : '82.3%')};
	margin: ${({ mobile }) =>
		mobile
			? `0 auto ${theme.marginVertical.mobile.homeComponent} auto`
			: `0 auto ${theme.marginVertical.desktop.homeComponent} auto`};
`;
const ProductosContainer = styled.div`
	width: 100%;
	height: ${({ mobile }) => (mobile ? '756px' : '524px')};
	display: flex;
	flex-direction: ${({ mobile }) => (mobile ? 'column' : 'row')};
	justify-content: ${({ mobile }) => (mobile ? 'center' : 'space-between')};
	${({ mobile }) => mobile && 'row-gap:12px'};
`;
const Group1 = styled.div`
	height: 100%;
	width: ${({ mobile }) => (mobile ? '100%' : '49.15%')};
	display: grid;
	grid-template-columns: ${({ mobile }) =>
		mobile ? '48.5% 48.5%' : '48.5% 48.5%'};
	grid-template-rows: ${({ mobile }) =>
		mobile ? '48.5% 48.5%' : '33.5% 63.5%'};
	gap: 3%;

	div:nth-child(3) {
		grid-row-start: 1;
		grid-row-end: 3;
		grid-column-start: 2;
	}
`;
const Group2 = styled.div`
	height: 100%;
	width: ${({ mobile }) => (mobile ? '100%' : '49.15%')};
	display: grid;
	grid-template-columns: ${({ mobile }) =>
		mobile ? '48.5% 48.5%' : '62% 35%'};
	grid-template-rows: ${({ mobile }) => (mobile ? '48.5% 48.5%' : '46% 51%')};

	gap: 3%;

	div:nth-child(1) {
		grid-row-start: 1;
		grid-column-start: 1;
		grid-column-end: 3;
	}
`;

const Category = styled.div`
	border-radius: 16px;
	padding: 1rem;
	position: relative;
	${({ background }) =>
		background
			? `background-image: url('${background}')`
			: `backgorund-color: ${theme.colors.primary}`};
	${({ background }) => background && 'background-repeat: no-repeat;'};
	${({ background }) => background && 'background-size: cover;'};

	> * {
		position: relative;
		z-index: 2;
	}

	:before {
		content: '';
		display: block;
		position: absolute;
		border-radius: 16px;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: linear-gradient(
			360deg,
			#4a4a4a 0%,
			rgba(236, 236, 236, 0.140625) 51.56%,
			rgba(255, 255, 255, 0) 100%
		);
		mix-blend-mode: multiply;
		transform: rotate(180deg);
		z-index: 1;
	}

	h3:nth-child(2) {
		display: none;
	}

	:hover {
		display: flex;
		justify-content: center;
		align-items: center;

		h3:nth-child(1) {
			display: none;
		}

		h3:nth-child(2) {
			display: block;
			cursor: pointer;
		}

		:before {
			background-color: black;
		}
	}

	h3:nth-child(2):hover {
		text-decoration: underline;
	}
`;
