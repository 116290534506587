import {
	FaFacebookF,
	FaInstagram,
	FaLinkedinIn,
	FaPhoneAlt,
} from 'react-icons/fa';

import { MdLocationOn } from 'react-icons/md';
import React from 'react';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useIsFormDisabled } from './../../Hooks/index';
import { useIsMobile } from '../../Hooks';

const Footer = () => {
	const mobile = useIsMobile();
	const { login, dashboard, blog, notFound } = useIsFormDisabled();

	return (
		<>
			{!login && !dashboard && (
				<Container id='footer'>
					<Box mobile={mobile}>
						<Img
							src='https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2FfooterLogo.png?alt=media&token=8b2ab815-93e4-4e34-aef3-423226d58653'
							alt='Logo de la empresa'
							mobile={mobile}
							login={login}
							dashboard={dashboard}
							blog={blog}
							notFound={notFound}
						/>
						<Data mobile={mobile}>
							<ContactInformation mobile={mobile}>
								<Location mobile={mobile}>
									<MdLocationOn
										style={{ marginRight: '0.5rem' }}
										size={24}
										color={theme.colors.white}
									/>
									Tomás de Baeza 1331. Córdoba Capital.
								</Location>
								<Phone mobile={mobile}>
									<FaPhoneAlt
										style={{ marginRight: '0.8rem' }}
										size={20}
										color={theme.colors.white}
									/>{' '}
									Contacto: 3512926705 / 3516135390
								</Phone>
							</ContactInformation>
							<SocialMedia mobile={mobile}>
								<FollowUsText>Seguinos en nuestras redes</FollowUsText>
								<IconContainer mobile={mobile}>
									<Icon
										id='foot'
										href='https://www.instagram.com/simple.abasto/'
										target='_blank'>
										<FaInstagram size={24} color={theme.colors.white} />
									</Icon>
									<Icon
										href='https://www.facebook.com/simpleabasto/'
										target='_blank'>
										<FaFacebookF size={19} color={theme.colors.white} />
									</Icon>
									<Icon
										href='https://www.linkedin.com/company/simple-abasto/'
										target='_blank'>
										<FaLinkedinIn size={24} color={theme.colors.white} />
									</Icon>
								</IconContainer>
							</SocialMedia>
						</Data>
					</Box>
				</Container>
			)}
		</>
	);
};

export default Footer;

const Container = styled.div`
	background-color: ${theme.colors.darkPrimary};
`;
const Box = styled.div`
	width: ${({ mobile }) => (mobile ? '91%' : '95%')};
	max-width: 1440px;
	margin: auto;
	display: flex;
	flex-direction: column;
`;
const Img = styled.img`
	align-self: center;
	margin: ${({ mobile, login, dashboard, blog, notFound }) =>
		mobile
			? login || dashboard || blog || notFound
				? '2.5rem 0 2rem 0'
				: '0 0 2rem 0'
			: '5.5rem 0 1.5rem 0'};
`;
const Data = styled.div`
	display: flex;
	flex-direction: ${({ mobile }) => (mobile ? 'column' : 'row')};
	justify-content: space-between;
	padding: 2rem 0 3.5rem 0;
	border-top: 2px solid ${theme.colors.white};
	${({ mobile }) => mobile && 'row-gap:40px'};
`;
const ContactInformation = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
`;
const SocialMedia = styled(ContactInformation)``;
const Phone = styled.span`
	display: flex;
	align-items: center;
	font-family: helvMedium;
	font-size: ${theme.fontSize.desktop.parrafo16};
	line-height: ${theme.lineHeigth.desktop.parrafo16};
	color: ${theme.colors.white};
`;
const Location = styled(Phone)``;
const FollowUsText = styled(Phone)``;
const IconContainer = styled.span`
	display: flex;
	justify-content: ${({ mobile }) => (mobile ? 'flex-start' : 'flex-end')};
	align-items: center;
	column-gap: 0.5rem;
`;
const Icon = styled.a`
	cursor: pointer;
`;
