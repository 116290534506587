import { BiChevronRight } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import React from 'react';
import StyledH2 from './../Shared/StyledH2/index';
import StyledParagraph18 from '../Shared/StyledParagraph18';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useIsMobile } from '../../Hooks';

const NotFoundPage = () => {
	const mobile = useIsMobile();
	return (
		<Container mobile={mobile}>
			<Box>
				<StyledH2 fontSize='48px'>
					Lo sentimos, no pudimos encontrar la página.
				</StyledH2>
				<StyledParagraph18 fontFamily='helvMedium'>
					Acá te dejamos algunos enlaces que pueden servirte de ayuda:
				</StyledParagraph18>
				<LinkContainer>
					<Link to='/' className='link'>
						Home Simple <BiChevronRight />
					</Link>
					<Link to='/' className='link'>
						Nosotros <BiChevronRight />
					</Link>
					<Link to='/' className='link'>
						Marcas Exclusivas <BiChevronRight />
					</Link>
					<Link to='/' className='link'>
						Blog <BiChevronRight />
					</Link>
				</LinkContainer>
			</Box>
		</Container>
	);
};

export default NotFoundPage;
const Container = styled.div`
	padding: ${theme.paddingVertical.desktop.notFound};
	width: ${({ mobile }) => (mobile ? '100%' : '95%')};
	max-width: 1440px;
	margin: 0 auto;
`;

const Box = styled.div`
	width: 40%;
	margin: 0 auto 0 auto;
	display: flex;
	flex-direction: column;
	row-gap: 40px;
`;
const LinkContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 8px;
	align-items: flex-start;
	.link {
		font-family: helvMedium;
		font-size: 1.125rem;
		line-height: 1.5rem;
		color: ${theme.colors.primary};
		text-decoration: none !important;
		padding: 10px 0;
	}
`;
