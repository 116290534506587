import Card from './../Card/index';
import React from 'react';
import StyledH1 from './../Shared/StyledH1/index';
import { advantages } from '../../Utils/index';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useIsMobile } from '../../Hooks';

const Ventajas = () => {
	const mobile = useIsMobile();
	return (
		<Container mobile={mobile}>
			<StyledH1
				id='Ventajas'
				align='center'
				alignMobile='center'
				margin={`${theme.marginVertical.desktop.h1}`}
				marginMobile={`${theme.marginVertical.mobile.h1}`}>
				Ventajas de Simple
			</StyledH1>
			<CardsContainer mobile={mobile}>
				{advantages.map((elem, i) => (
					<Card key={i} adv={elem} />
				))}
			</CardsContainer>
		</Container>
	);
};

export default Ventajas;

const Container = styled.div`
	width: ${({ mobile }) => (mobile ? '91%' : '82.3%')};
	margin: ${({ mobile }) =>
		mobile
			? `0 auto ${theme.marginVertical.mobile.homeComponent} auto`
			: `0 auto ${theme.marginVertical.desktop.homeComponent} auto`};
`;
const CardsContainer = styled.div`
	display: flex;
	flex-direction: ${({ mobile }) => (mobile ? 'column' : 'row')};
	justify-content: space-between;
`;
