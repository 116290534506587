import React from 'react';
import styled from 'styled-components';
import theme from './../../../Constants/theme';
import { useIsMobile } from '../../../Hooks';

const StyledH2 = (props) => {
	const mobile = useIsMobile();
	return (
		<Container {...props} mobile={mobile}>
			{props.children}
		</Container>
	);
};

export default StyledH2;

const Container = styled.h2`
	font-family: helvExtraBold;
	font-size: ${({ mobile, fontSize, fontSizeMobile }) =>
		mobile
			? fontSizeMobile
				? `${fontSizeMobile}`
				: `${theme.fontSize.mobile.h2}`
			: fontSize
			? `${fontSize}`
			: `${theme.fontSize.desktop.h2}`};
	line-height: ${({ mobile, lineHeigth, lineHeigthMobile }) =>
		mobile
			? lineHeigthMobile
				? `${lineHeigthMobile}`
				: `${theme.lineHeigth.mobile.h2}`
			: lineHeigth
			? `${lineHeigth}`
			: `${theme.lineHeigth.desktop.h2}`};
	color: ${({ color }) => (color ? `${color}` : `${theme.colors.darkGrey}`)};
	${({ mobile, margin, marginMobile }) =>
		(margin || marginMobile) &&
		(mobile ? `margin: ${marginMobile}` : `margin: ${margin}`)};
	${({ mobile, padding, paddingMobile }) =>
		(padding || paddingMobile) &&
		(mobile ? `padding: ${paddingMobile}` : `padding: ${padding}`)};
	${({ width, widthMobile, mobile }) =>
		(width || widthMobile) &&
		(mobile ? `width: ${widthMobile}` : `width: ${width}`)};
	${({ mobile, align, alignMobile }) =>
		(align || alignMobile) &&
		(mobile ? `text-align: ${alignMobile}` : `text-align: ${align}`)};
`;
