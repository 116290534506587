import React, { useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';

import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import BlogItem from './BlogItem';
import { Link } from 'react-router-dom';
import Loader from '../../Shared/Loader';
import StyledH1 from './../../Shared/StyledH1/index';
import StyledParagraph18 from '../../Shared/StyledParagraph18';
import { db } from '../../../Firebase/index';
import styled from 'styled-components';
import theme from './../../../Constants/theme';

const BlogList = () => {
	const [list, setList] = useState(null);

	useEffect(() => {
		onSnapshot(
			collection(db, 'Blogs'),
			(snapshot) => {
				const blogList = snapshot.docs.map((doc) => ({
					...doc.data(),
					id: doc.id,
				}));

				setList(blogList);
			},
			(error) => console.log('error', error)
		);
	}, []);

	return (
		<Container>
			<Box>
				<StyledH1>Blogs</StyledH1>
				<LinkContainer>
					<Link to='/admin/blogs/crear-blog'>
						<AiOutlineAppstoreAdd
							className='icon'
							size={30}
							color={`${theme.colors.negro80}`}
						/>
					</Link>
				</LinkContainer>
			</Box>
			<ListContainer center={list && list.length !== 0 ? false : true}>
				{list ? (
					list.length !== 0 ? (
						list.map((e, i) => <BlogItem key={i} data={e} />)
					) : (
						<StyledParagraph18 fontFamily='helvMedium'>
							*No existen blogs para mostrar
						</StyledParagraph18>
					)
				) : (
					<Loader />
				)}
			</ListContainer>
		</Container>
	);
};

export default BlogList;

const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5%;
	row-gap: 2rem;
`;
const Box = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 50%;
`;
const LinkContainer = styled.div`
	display: flex;
	column-gap: 20px;
	.icon:hover {
		color: ${theme.colors.primary} !important;
	}
`;
const ListContainer = styled.div`
	flex: 1 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
	row-gap: 20px;
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
