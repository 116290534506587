import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
	apiKey: `${process.env.REACT_APP_APIKEY}`,
	authDomain: `${process.env.REACT_APP_AUTHDOMAIN}`,
	projectId: `${process.env.REACT_APP_PROJECTID}`,
	storageBucket: `${process.env.REACT_APP_STORAGEBUCKET}`,
	messagingSenderId: `${process.env.REACT_APP_MESSAGINGSENDERID}`,
	appId: `${process.env.REACT_APP_APPID}`,
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
