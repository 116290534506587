import React, { useEffect, useMemo, useState } from 'react';
import {
	collection,
	doc,
	getDocs,
	query,
	updateDoc,
	where,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../../Components/Shared/Loader';
import PrimaryButton from '../../Components/Shared/PrimaryButton';
import Select from 'react-select';
import StyledH1 from './../../Components/Shared/StyledH1/index';
import StyledParagraph16 from '../../Components/Shared/StyledParagraph16';
import StyledParagraph18 from '../../Components/Shared/StyledParagraph18';
import { db } from '../../Firebase';
import { storage } from './../../Firebase/index';
import styled from 'styled-components';
import theme from '../../Constants/theme';
import { useWindowSize } from './../../Hooks/index';

const EditElementContainer = ({ fieldsList, title }) => {
	const [blogData, setBlogData] = useState(null);
	const [file, setFile] = useState(null);
	const [pending, setPending] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [imgUrl, setImgUrl] = useState(null);
	const [edited, setEdited] = useState(false);
	const [supported, setSupported] = useState(true);

	const { height } = useWindowSize();
	const navigate = useNavigate();
	const id = useParams().currentBlog;

	useEffect(() => {
		const getBlogs = async () => {
			const q = query(collection(db, 'Blogs'), where('blogId', '==', id));
			const querySnapshot = await getDocs(q);

			return querySnapshot;
		};

		getBlogs().then((response) => {
			response.docs.map((doc) => setBlogData({ id: doc.id, ...doc.data() }));
		});
	}, [id]);

	const handleChange = (name, value) => {
		setEdited(true);
		setBlogData((current) => {
			return { ...current, [name]: value };
		});
	};

	const handleFile = (e) => {
		setEdited(true);
		const type = e.target.files[0].type;

		if (type.includes('jpg') || type.includes('jpeg') || type.includes('png')) {
			setFile(e.target.files[0]);
			setSupported(true);
		} else {
			setSupported(false);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setPending(true);
		const reference = doc(db, 'Blogs', blogData.id);

		if (file) {
			const name = `${file.name}`;
			const blogImgRef = ref(storage, `Blogs/${name}`);
			const date = Date.now();

			uploadBytes(blogImgRef, file)
				.then((snapshot) => {
					updateDoc(reference, { ...blogData, img: file.name, date });
				})
				.finally(() => {
					setTimeout(() => {
						setPending(false);
						navigate('/admin/blogs');
					}, 1000);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			const date = Date.now();

			updateDoc(reference, { ...blogData, date })
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					setTimeout(() => {
						setPending(false);
						navigate('/admin/blogs');
					}, 1000);
				});
		}
	};

	useEffect(() => {
		edited &&
		blogData &&
		blogData?.title !== '' &&
		blogData?.body !== '' &&
		blogData?.category !== '' &&
		supported
			? setDisabled(false)
			: setDisabled(true);

		const reference = blogData?.img && ref(storage, `Blogs/${blogData?.img}`);
		reference && getDownloadURL(reference).then((url) => setImgUrl(url));
	}, [blogData, file, edited, supported]);

	const imgInput = useMemo(() => {
		return (
			<>
				<FileInput name='file' type='file' onChange={(e) => handleFile(e)} />
				<Preview
					background={
						supported ? (file ? URL.createObjectURL(file) : imgUrl) : false
					}>
					{!supported && (
						<StyledParagraph16 margin='10px 0 0 10px'>
							Tipo de archivo no soportado
						</StyledParagraph16>
					)}
				</Preview>
				<StyledParagraph16 margin='10px 0 0 0'>
					Extensiones de archivos permitidas: .JPG, .JPEG Y .PNG
				</StyledParagraph16>
			</>
		);
	}, [file, imgUrl, supported]);

	const getFieldsType = (type, name, options) => {
		switch (type) {
			case 'text':
				return (
					<TextInput
						defaultValue={blogData[`${name}`]}
						onChange={(e) => handleChange(name, e.target.value)}
					/>
				);
			case 'textArea':
				return (
					<TextAreaInput
						defaultValue={blogData[`${name}`]}
						onChange={(e) => handleChange(name, e.target.value)}
						rows='5'
					/>
				);
			case 'img':
				return imgInput;
			case 'select':
				return (
					<Select
						name={name}
						defaultValue={options.find((e) => e.value === blogData?.category)}
						placeholder='Elige una categoria'
						options={options}
						onChange={(e) => handleChange(name, e.value)}
					/>
				);
			default:
				return <TextInput />;
		}
	};

	return (
		<Container height={height}>
			<StyledH1>{title}</StyledH1>
			{blogData ? (
				Object.values(blogData).length === 0 ? (
					<StyledParagraph18>No existen blogs para mostrar</StyledParagraph18>
				) : (
					<>
						<FieldContainer>
							{fieldsList.map((e, i) => (
								<InputContainer key={i}>
									<StyledLabel>
										{e.inputLabel}
										{e.isRequired && e.name !== 'img' && (
											<Required>* campo obligatorio</Required>
										)}
									</StyledLabel>
									{getFieldsType(e.type, e.name, e.options)}
								</InputContainer>
							))}
						</FieldContainer>
						<PrimaryButton
							disabled={disabled}
							width='15%'
							onClick={handleSubmit}>
							{!pending ? (
								'Modificar'
							) : (
								<Loader
									flex={true}
									marginVertical='0'
									margin='0'
									size='27'
									border='3'
									color={`${theme.colors.white}`}
								/>
							)}
						</PrimaryButton>
					</>
				)
			) : (
				<Loader />
			)}
		</Container>
	);
};
export default EditElementContainer;

const Container = styled.div`
	height: ${({ height }) => height && `${height}px`};
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 5%;
	row-gap: 2rem;
`;

const FieldContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	overflow-y: scroll;
	height: 77%;
`;

const InputContainer = styled.div`
	width: 60%;
	margin: 0 0 2% 0;
	display: flex;
	flex-direction: column;
`;

const StyledLabel = styled.label`
	font-family: helvBlack;
	font-size: ${theme.fontSize.desktop.parrafo18};
	line-height: ${theme.lineHeigth.desktop.parrafo18};
	margin-bottom: 0.45rem;
	color: ${theme.colors.darkGrey};
	display: flex;
	align-items: center;
`;

const TextInput = styled.input`
	width: 100%;
	font-family: helvMedium;
	font-size: ${theme.fontSize.desktop.parrafo16};
	line-height: ${theme.lineHeigth.desktop.parrafo16};
	color: ${theme.colors.darkGrey};
	padding: 0.45rem 1rem;
	border-radius: 8px;
	border: 1px solid ${theme.colors.grey};
	:focus {
		outline: 0;
	}
`;

const TextAreaInput = styled.textarea`
	width: 100%;
	font-family: helvMedium;
	font-size: ${theme.fontSize.desktop.parrafo16};
	line-height: ${theme.lineHeigth.desktop.parrafo16};
	color: ${theme.colors.darkGrey};
	padding: 0.45rem 1rem;
	border-radius: 8px;
	border: 1px solid ${theme.colors.grey};
	:focus {
		outline: 0;
	}
`;

const Required = styled.span`
	font-size: 0.75rem;
	color: ${theme.colors.primary};
	margin: 0 0 0 0.35rem;
`;

const FileInput = styled.input``;

const Preview = styled.div`
	width: 300px;
	height: 200px;
	margin: 1rem 0 0 0;
	${({ background }) =>
		!background && `background-color: ${theme.colors.ligthGrey} `};
	${({ background }) => background && `background-image: url('${background}')`};
	${({ background }) => background && 'background-size: cover'};
	${({ background }) => background && 'background-position: center center'};
`;
