import React from 'react';
import StyledParagraph16 from '../Shared/StyledParagraph16';
import StyledParagraph18 from './../Shared/StyledParagraph18/index';
import styled from 'styled-components';
import theme from '../../Constants/theme';
import { useIsMobile } from '../../Hooks/index';

const Card = ({ adv }) => {
	const { name, srcImg, text } = adv;
	const mobile = useIsMobile();
	return (
		<CardContainer mobile={mobile}>
			<Img mobile={mobile} src={srcImg} />
			<InfoContainer>
				<StyledParagraph18 margin='0 0 16px 0' marginMobile='0 0 8px 0'>
					{name}
				</StyledParagraph18>
				<StyledParagraph16 margin='0' marginMobile='0 25px 0 0'>
					{text}
				</StyledParagraph16>
			</InfoContainer>
		</CardContainer>
	);
};

export default Card;

const CardContainer = styled.div`
	width: ${({ mobile }) => (mobile ? '100%' : '24%')};
	${({ mobile }) => !mobile && 'max-width: 278px'};
	display: flex;
	flex-direction: ${({ mobile }) => (mobile ? 'row' : 'column')};
	align-items: flex-start;
	${({ mobile }) => mobile && 'margin:0 0 16px 0'};
	padding: ${({ mobile }) => (mobile ? '1rem' : '1.5rem 2rem 1.75rem 2rem')};
	background-color: ${theme.colors.white};
	border: 1px solid #e6e6e6;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
	border-radius: 24px;
	row-gap: 12px;
`;

const Img = styled.img`
	margin: ${({ mobile }) => (mobile ? '0 10px 0 0' : '0')};
`;
const InfoContainer = styled.div``;
