import React, { useState } from 'react';

import Carousel from 'react-bootstrap/Carousel';
import { useIsMobile } from '../../Hooks';

const Carrusel = () => {
	const [index, setIndex] = useState(0);
	const mobile = useIsMobile();

	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};

	return (
		<Carousel
			touch={true}
			interval={3000}
			controls={!mobile ? true : false}
			prevIcon={<img src='./img/ArrowLeft.png' alt='Volver' width='56' />}
			nextIcon={<img src='./img/ArrowRight.png' alt='Volver' width='56' />}
			activeIndex={index}
			onSelect={handleSelect}>
			<Carousel.Item>
				{!mobile ? (
					<img
						className='d-block w-100'
						src='./img/slide1.png'
						alt='First slide'
					/>
				) : (
					<img
						style={{ borderRadius: 16 }}
						className='d-block w-100'
						src='./img/slide1Mobile.png'
						alt='First slide'
					/>
				)}
			</Carousel.Item>
			<Carousel.Item>
				{!mobile ? (
					<img
						className='d-block w-100'
						src='./img/slide2.png'
						alt='Second slide'
					/>
				) : (
					<img
						style={{ borderRadius: 16 }}
						className='d-block w-100'
						src='./img/slide2Mobile.png'
						alt='Second slide'
					/>
				)}
			</Carousel.Item>
			<Carousel.Item>
				{!mobile ? (
					<img
						className='d-block w-100'
						src='./img/slide3.png'
						alt='Thirs slide'
					/>
				) : (
					<img
						style={{ borderRadius: 16 }}
						className='d-block w-100'
						src='./img/slide3Mobile.png'
						alt='Thirs slide'
					/>
				)}
			</Carousel.Item>
			<Carousel.Item>
				{!mobile ? (
					<img
						className='d-block w-100'
						src='./img/slide4.png'
						alt='Fourth slide'
					/>
				) : (
					<img
						style={{ borderRadius: 16 }}
						className='d-block w-100'
						src='./img/slide4Mobile.png'
						alt='Fourth slide'
					/>
				)}
			</Carousel.Item>
		</Carousel>
	);
};

export default Carrusel;
