import Description from './../../Components/Description/index';
import { Helmet } from 'react-helmet';
import IntroducingVideo from './../../Components/IntroducingVideo/index';
import Location from './../../Components/Location/index';
import React from 'react';
import WhatsappIcon from './../../Components/Shared/WhatsappIcon/index';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useIsMobile } from '../../Hooks';

const NosotrosContainer = () => {
	const mobile = useIsMobile();
	return (
		<>
			<Helmet>
				<title>Nosotros</title>
				<link rel='canonical' href='http://simpleabasto.com.ar/nosotros' />
				<meta
					name='description'
					content='Desde el año 2014, brindamos desarrollo, representación y comercialización de marcas de retail, basados en el servicio y la satisfacción del cliente. Nos proponemos simplificar tus compras y unificar tus proveedores. Trabajamos con stock permanente, lo que nos permite dar una rápida y dinámica respuesta a tus necesidades. Venimos a hacerte la vida más Simple.'
				/>
				<meta
					name='keywords'
					content='representación, comercialización, retail, simple,abasto, distribución,bares, restaurant, restauranes, abasto gastronómico, insumos,insumos bares, pedidos bares, compras, cocinas, swift, hellmans,tregar, canal tradicional, canal gastronómico,  quento, córdoba, argentina'
				/>
			</Helmet>
			<Container mobile={mobile}>
				<WhatsappIcon />
				<Box mobile={mobile}>
					<Description />
					<IntroducingVideo />
				</Box>
				<Location />
			</Container>
		</>
	);
};

export default NosotrosContainer;

const Container = styled.div`
	width: ${({ mobile }) => (mobile ? '91%' : '95%')};
	max-width: 1440px;
	margin: 0 auto 0 auto;
	padding: 2.5rem 0 0 0;
`;
const Box = styled.div`
	width: ${({ mobile }) => (mobile ? '100%' : '82.3%')};
	margin: ${({ mobile }) =>
		mobile
			? `${theme.marginVertical.mobile.nosotrosComponent} auto
		${theme.marginVertical.mobile.nosotrosComponent} auto`
			: `${theme.marginVertical.desktop.nosotrosComponent} auto
		${theme.marginVertical.desktop.nosotrosComponent} auto`};
	display: flex;
	flex-direction: ${({ mobile }) => (mobile ? 'column' : 'row')};
	justify-content: space-between;
`;
