import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { navLinks, scrollTo } from './../../Utils/index';

import BurgerMenu from './../BurgerMenu/index';
import StyledParagraph16 from './../Shared/StyledParagraph16/index';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useIsFormDisabled } from './../../Hooks/index';
import { useIsMobile } from '../../Hooks/index';

const NavBar = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const mobile = useIsMobile();
	const location = useLocation().pathname;
	const navigate = useNavigate();
	const { dashboard, login } = useIsFormDisabled();

	const handleGoTo = (ref, name) => {
		scrollTo(location, navigate, ref, name);
	};

	return (
		<>
			{!login && !dashboard && (
				<Container id='nav' mobile={mobile}>
					<Box mobile={mobile}>
						<Logo
							mobile={mobile}
							onClick={() => {
								handleGoTo('/', 0);
								mobile && setMenuOpen(false);
							}}
							src='https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Flogo.png?alt=media&token=e60f735c-4fa8-4005-a0e7-b47dcf006592'
							alt='Logo de la empresa'></Logo>
						{mobile ? (
							<BurgerMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
						) : (
							<LinkContainer>
								{navLinks.map((elem, index) => (
									<NavLink
										key={index}
										className='linkContainer'
										onClick={() => handleGoTo(elem.href, elem.id)}
										style={{ textDecoration: 'none' }}>
										<StyledParagraph16
											className='link'
											margin='0 1rem 0 1rem'
											color={
												elem.name === 'Contactar'
													? `${theme.colors.primary}`
													: undefined
											}
											fontFamily={
												elem.name === 'Contactar' ? 'helvBlack' : undefined
											}>
											{elem.name}
										</StyledParagraph16>
									</NavLink>
								))}
							</LinkContainer>
						)}
					</Box>
				</Container>
			)}
		</>
	);
};

export default NavBar;

const Container = styled.div`
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
	z-index: ${theme.zIndex.header};
	position: fixed;
	background-color: ${theme.colors.white};
	width: 100%;
`;
const Box = styled.nav`
	width: ${({ mobile }) => (mobile ? '100%' : '95%')};
	${({ mobile }) => !mobile && 'max-width: 1440px'};
	margin: ${({ mobile }) => (mobile ? '0' : 'auto')};
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const Logo = styled.img`
	${({ mobile }) => mobile && 'width: 93px'};
	margin: ${({ mobile }) => (mobile ? '0 0 -26px 16px' : '0 0 -39px 8.88%')};
	position: relative;
	z-index: ${theme.zIndex.header};
	cursor: pointer;
`;
const LinkContainer = styled.div`
	display: flex;
	justify-content: space-around;
	margin: 0 8.88% 0 0;

	.link {
		position: relative;
	}

	.link::after {
		position: absolute;
		content: '';
		width: 0%;
		height: 3px;
		background-color: ${({ contacto }) =>
			contacto ? `${theme.colors.primary}` : `${theme.colors.darkGrey}`};
		left: 50%;
		bottom: -5px;
		transition: all 0.4s ease-in-out;
	}

	.linkContainer:hover .link::after {
		width: 100%;
		left: 0;
	}
`;
