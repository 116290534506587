import { homeBrands, scrollTo } from './../../Utils/index';
import { useLocation, useNavigate } from 'react-router-dom';

import PrimaryButton from '../Shared/PrimaryButton/index';
import React from 'react';
import StyledH1 from './../Shared/StyledH1/index';
import { brands } from '../../Utils';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useIsMobile } from '../../Hooks/index';

const MarcasExclusivas = ({ home = true }) => {
	const mobile = useIsMobile();
	const location = useLocation().pathname;
	const navigate = useNavigate();
	const handleClick = () => {
		scrollTo(location, navigate, '/nuestras-marcas', 200);
	};

	return (
		<Container mobile={mobile}>
			<StyledH1
				id='Marcas'
				mobile={mobile}
				align='center'
				alignMobile='center'
				margin={`${theme.marginVertical.desktop.h1}`}
				marginMobile={`${theme.marginVertical.mobile.h1}`}>
				Marcas exclusivas
			</StyledH1>
			<BrandContainer mobile={mobile} isHome={home}>
				{home
					? homeBrands.map((elem) => (
							<Img
								mobile={mobile}
								isHome={true}
								key={elem.order}
								src={`${elem.background}`}
								alt=''
							/>
					  ))
					: brands.map((elem) => (
							<Img
								mobile={mobile}
								isHome={home}
								key={elem.order}
								src={`${elem.background}`}
								alt=''
							/>
					  ))}
			</BrandContainer>
			{home && (
				<PrimaryButton alignSelf='center' onClick={handleClick}>
					Ver todas las marcas
				</PrimaryButton>
			)}
		</Container>
	);
};

export default MarcasExclusivas;
const Container = styled.div`
	width: ${({ mobile }) => !mobile && '82.3%'};
	margin: ${({ mobile }) =>
		mobile
			? `0 28px ${theme.marginVertical.mobile.homeComponent} 28px`
			: `0 auto ${theme.marginVertical.desktop.homeComponent} auto`};
	display: flex;
	flex-direction: column;
`;

const BrandContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: ${({ isHome, mobile }) =>
		isHome ? (mobile ? 'wrap' : 'nowrap') : 'wrap'};
	-ms-flex-wrap: ${({ isHome, mobile }) =>
		isHome ? (mobile ? 'wrap' : 'nowrap') : 'wrap'};
	justify-content: ${({ isHome, mobile }) =>
		isHome ? 'space-between' : mobile ? 'space-between' : 'start'};
	${({ isHome, mobile }) =>
		isHome && mobile ? 'margin: 0 0 9% 0' : 'margin: 0 0 5.6% 0'};
	column-gap: ${({ isHome, mobile }) =>
		!isHome ? (mobile ? '32px' : '93px') : mobile && '32px'};
	row-gap: ${({ isHome, mobile }) => (!isHome ? '60px' : mobile && '32px')};
`;
const Img = styled.img`
	width: ${({ isHome, mobile }) =>
		mobile ? '80px' : isHome ? '100px' : '120px'};
`;
