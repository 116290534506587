import React from 'react';
import StyledH2 from './../Shared/StyledH2/index';
import StyledParagraph16 from '../Shared/StyledParagraph16';
import styled from 'styled-components';
import theme from '../../Constants/theme';
import { useIsMobile } from '../../Hooks/index';

const Description = () => {
	const mobile = useIsMobile();
	return (
		<Container mobile={mobile}>
			<StyledH2
				id='Nosotros'
				margin={`${theme.marginVertical.desktop.h2Nosotros} `}
				marginMobile={`${theme.marginVertical.mobile.h2Nosotros}`}>
				Nosotros
			</StyledH2>
			<StyledParagraph16 margin='0' marginMobile='0 0 32px 0'>
				Desde el año 2014, brindamos desarrollo, representación y
				comercialización de marcas de retail, basados en el servicio y la
				satisfacción del cliente. <br />
				<br />
				Nos proponemos simplificar tus compras y unificar tus proveedores.
				Trabajamos con stock permanente, lo que nos permite dar una rápida y
				dinámica respuesta a tus necesidades. <br />
				<br />
				Venimos a hacerte la vida más <span className='bold'>Simple</span>.
			</StyledParagraph16>
		</Container>
	);
};

export default Description;
const Container = styled.div`
	width: ${({ mobile }) => (mobile ? '100%' : '32%')};
	.bold {
		font-family: helvExtraBold;
	}
`;
