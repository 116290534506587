import PrimaryButton from '../Shared/PrimaryButton';
import React from 'react';
import StyledH2 from './../Shared/StyledH2/index';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useIsMobile } from '../../Hooks/index';

const DownloadCatalogo = () => {
	const mobile = useIsMobile();

	const onDownloadHandler = () => {
		const url =
			'https://drive.google.com/file/d/1Caj4eyKzac1zRlm7B9U6dYiGz-BGhOmX/view';
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('target', '_blank');
		link.click();
	};
	return (
		<Container mobile={mobile}>
			<Box mobile={mobile}>
				<DownloadContainer mobile={mobile}>
					<StyledH2
						id='Catalogo'
						color={`${theme.colors.white}`}
						margin='0 0 1.5rem 0'
						marginMobile='0 0 1.5rem 0'>
						Descargá nuestro catálogo
					</StyledH2>
					<PrimaryButton
						onClick={onDownloadHandler}
						background={`${theme.colors.white}`}
						backgroundHover={`${theme.colors.ligthGrey}`}
						color={`${theme.colors.primary}`}
						colorHover={`${theme.colors.darkPrimary}`}>
						Descargar
					</PrimaryButton>
				</DownloadContainer>
				<Img mobile={mobile}></Img>
			</Box>
		</Container>
	);
};

export default DownloadCatalogo;
const Container = styled.div`
	width: ${({ mobile }) => (mobile ? '91%' : '82.3%')};
	max-width: 1440px;
	margin: ${({ mobile }) =>
		mobile
			? `0  auto ${theme.marginVertical.mobile.homeComponent} auto`
			: `${theme.marginVertical.desktop.catalogo} auto
		${theme.marginVertical.desktop.homeComponent} auto`};
`;
const Box = styled.div`
	width: 100%;
	margin: 0 auto;
	border-radius: 32px;
	background-color: ${theme.colors.primary};
	display: flex;
	flex-direction: ${({ mobile }) => (mobile ? 'column' : 'row')};
	justify-content: center;
	${({ mobile }) => mobile && 'align-items: center'};

	:hover {
		${({ mobile }) =>
			!mobile && `background-color: ${theme.colors.darkPrimary}`};
	}

	@keyframes zoom {
		from {
			background-size: 100%;
		}
		to {
			background-size: 120%;
		}
	}

	:hover div:nth-child(2) {
		${({ mobile }) => !mobile && 'animation-name: zoom'};
		${({ mobile }) => !mobile && 'animation-duration: 0.7s'};
		${({ mobile }) => !mobile && 'animation-delay: 0.1s'};
		${({ mobile }) => !mobile && 'animation-fill-mode: forwards'};
	}
`;

const DownloadContainer = styled.div`
	width: ${({ mobile }) => (mobile ? '86%' : '31.6%')};
	margin: ${({ mobile }) =>
		mobile
			? `${theme.marginVertical.mobile.catalogoBox}`
			: `${theme.marginVertical.desktop.catalogoBox} 0`};
`;
const Img = styled.div`
	width: ${({ mobile }) => (mobile ? '100%' : '45%')};
	${({ mobile }) => mobile && 'height: 252px'};
	margin-top: ${({ mobile }) => (mobile ? '0' : '-78px')};
	background-image: url('https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2FcatalogoBackground.png?alt=media&token=e4f16f98-54b5-4fad-9e50-c723a99820df');
	background-size: ${({ mobile }) => (mobile ? 'cover' : '100%')};
	background-position: top right;
	background-repeat: no-repeat;
`;
