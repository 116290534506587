const theme = {
	colors: {
		blogGrey: '#EDEDED',
		darkPrimary: '#C10F1D',
		primary: '#E52232',
		lightPrimary: '#FF848E',
		white: '#FFFFFF',
		darkGrey: '#3D3D3D',
		grey: '#B5B5B5',
		ligthGrey: '#F5F5F5',
		negro80: 'rgba(0, 0, 0, 0.8)',
		whatsappBackground: '#5CE26C',
		blogCategoryGrey: '#E6E6E6',
	},
	fontSize: {
		desktop: {
			h1: '56px',
			h2: '40px',
			h3: '24px',
			parrafo18: '18px',
			parrafo16: '16px',
		},
		mobile: {
			h1: '32px',
			h2: '26px',
			h3: '20px',
			parrafo16: '16px',
			parrafo14: '14px',
		},
	},
	fontWeight: { extraBold: 800, black: 900, medium: 500 },
	lineHeigth: {
		desktop: {
			h1: '100%',
			h2: '100%',
			h3: '35px',
			parrafo18: '26px',
			parrafo16: '24px',
		},
		mobile: {
			h1: '32px',
			h2: '26px',
			h3: '29px',
			parrafo16: '24px',
			parrafo14: '21px',
		},
	},
	marginVertical: {
		desktop: {
			h1: '0 0 56px 0',
			h2Nosotros: '0 0 32px 0',
			h2Marcas: '56px',
			h2Form: '72px',
			productosComponent: '1px',
			homeComponent: '124px',
			nosotrosComponent: '124px',
			bannerComponent: '96px',
			catalogoBox: '56px',
			catalogo: '64px',
		},
		mobile: {
			bannerComponent: '48px',
			h1: '0 0 40px 0',
			h2Form: '40px',
			homeComponent: '72px',
			catalogoBox: '24px 0 32px 0',
			h2Nosotros: '0 0 24px 0',
			nosotrosComponent: '86px',
		},
	},
	paddingVertical: {
		desktop: {
			home: '100px',
			section: '128px',
			blog: '11rem 0 7.75rem 0',
			notFound: '11rem 0 7.75rem 0',
		},
		mobile: {
			blog: '8.3rem 0 4.5rem 0',
		},
	},
	paddingHorizontal: { desktop: { section: '56px' } },
	zIndex: {
		wpIcon: 30,
		header: 15,
		mobileMenu: 10,
		home: 9,
		nosotros: 9,
		form: 10,
		blogItem: 9,
		modalBlogItem: 10,
	},
};

export default theme;
