import './App.css';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';

import BlogContainer from './Containers/BlogContainer';
import BlogDetailContainer from './Containers/BlogDetailContainer/index';
import ContactForm from './Components/Shared/Form';
import Dashboard from './Containers/DashboardContainer/index';
import Footer from './Components/Footer';
import HomeContainer from './Containers/HomeContainer/index';
import Loader from './Components/Shared/Loader/index';
import Login from './Containers/LogInContainer';
import NavBar from './Components/NavBar';
import NosotrosContainer from './Containers/NosotrosContainer/index';
import NotFoundPage from './Components/404page';
import NuestrasMarcasContainer from './Containers/NuestrasMarcasContainer/index';
import { auth } from './Firebase';

function App() {
	const [loading, setLoading] = useState(true);
	const [currentUser, setCurrentUser] = useState(false);

	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			setCurrentUser(user);
		});
		setTimeout(() => {
			setLoading(false);
		}, 1500);
	}, []);

	const loaderContainer = {
		width: '100%',
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	};

	return (
		<>
			{loading ? (
				<div style={loaderContainer}>
					<Loader />
				</div>
			) : (
				<BrowserRouter>
					<NavBar />
					<Routes>
						<Route path='/' element={<HomeContainer />} />
						<Route path='/nosotros' element={<NosotrosContainer />} />
						<Route
							path='/nuestras-marcas'
							element={<NuestrasMarcasContainer />}
						/>
						<Route
							path='/admin/login'
							element={<Login currentUser={currentUser} />}
						/>

						<Route path='/blogs' element={<BlogContainer />} />
						<Route path='/blogs/:category' element={<BlogContainer />} />
						<Route
							path='/blogs/detalle/:blogId'
							element={<BlogDetailContainer />}
						/>
						<Route path='*' element={<Navigate replace to='/404' />} />
						<Route path='/404' element={<NotFoundPage />} />

						{currentUser && (
							<>
								<Route path='/admin/inicio' element={<Dashboard />} />
								<Route path='/admin/blogs' element={<Dashboard />} />
								<Route path='/admin/blogs' element={<Dashboard />} />
								<Route path='/admin/blogs/crear-blog' element={<Dashboard />} />
								<Route
									path='/admin/blogs/editar-blog/:currentBlog'
									element={<Dashboard />}
								/>
							</>
						)}
					</Routes>
					<ContactForm />
					<Footer />
				</BrowserRouter>
			)}
		</>
	);
}

export default App;
