import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

const isClient = () => typeof window === 'object';

const getWindowSize = () => {
	if (!isClient()) return null;

	return {
		window,
		width: window.innerWidth,
		height: window.innerHeight,
	};
};

export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState(getWindowSize);

	useEffect(() => {
		const handleResize = () => {
			setWindowSize(getWindowSize());
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowSize;
};

export const useIsMobile = () => {
	const windowSize = useWindowSize();
	return windowSize && windowSize.width < 451;
};

export const useIsFormDisabled = () => {
	const dashboard = useLocation().pathname.includes('admin');
	const login = useLocation().pathname.includes('login');
	const blog = useLocation().pathname.includes('blog');
	const notFound = useLocation().pathname.includes('404');

	return { dashboard, login, blog, notFound };
};
