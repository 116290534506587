import BlogList from './../BlogList/index';
import EditElementContainer from './../../../Containers/EditElementContainer/index';
import NewElementContainer from './../../../Containers/NewElementContainer/index';
import React from 'react';
import StyledH1 from './../../Shared/StyledH1/index';
import StyledParagraph18 from './../../Shared/StyledParagraph18/index';
import { blogFields } from './../../../Utils/index';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from '../../../Hooks';

const AdminSubElements = ({ selectedOption }) => {
	const location = useLocation();
	const { height } = useWindowSize();

	function getSelectedTab() {
		switch (selectedOption) {
			case 'Inicio':
				return (
					<InicioContainer height={height}>
						<StyledH1>Inicio</StyledH1>
						<Box>
							<StyledParagraph18>
								Bienvenidx a tu panel de usuario
							</StyledParagraph18>
						</Box>
					</InicioContainer>
				);
			case 'Blogs':
				return (
					<>
						{location.pathname.includes('blogs/crear-blog') ? (
							<NewElementContainer
								fieldsList={blogFields}
								title='Cargar nota de blog'
							/>
						) : location.pathname.includes('blogs/editar-blog') ? (
							<EditElementContainer
								fieldsList={blogFields}
								title='Editar nota de blog'
							/>
						) : (
							<BlogList />
						)}
					</>
				);

			default:
				return '';
		}
	}
	return <Container>{getSelectedTab()}</Container>;
};

export default AdminSubElements;

const Container = styled.div`
	width: 80%;
	height: 100vh;
`;

const InicioContainer = styled.div`
	width: 100%;
	height: ${({ height }) => height && `${height}px`};
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 5%;
	row-gap: 2rem;
`;

const Box = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
`;
