import React, { useMemo } from 'react';

import styled from 'styled-components';
import theme from '../../Constants/theme';
import { useIsMobile } from '../../Hooks';

const Banner = () => {
	const mobile = useIsMobile();
	const bannerSrc = './img/bannerBackgroundDesktop.png';
	const bannerSrcMobile = './img/bannerBackgroundMobile.png';
	const Banner = useMemo(
		() => (
			<Container mobile={mobile}>
				<Img mobile={mobile} src={!mobile ? bannerSrc : bannerSrcMobile} />
			</Container>
		),
		[mobile, bannerSrc, bannerSrcMobile]
	);
	return Banner;
};

export default Banner;

const Container = styled.div`
	border-radius: 0 0 30px 30px;
	margin: ${({ mobile }) =>
		mobile
			? `-29px 0 ${theme.marginVertical.mobile.bannerComponent} 0`
			: `0 0 ${theme.marginVertical.desktop.bannerComponent} 0`};
`;
const Img = styled.img`
	max-width: 100%;
	${({ mobile }) => mobile && 'width:100%'};
`;
