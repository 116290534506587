import React, { useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';

import BlogCard from '../BlogCard';
import StyledH3 from '../Shared/StyledH3';
import { db } from '../../Firebase';
import { scrollToTop } from './../../Utils/index';
import styled from 'styled-components';
import { useIsMobile } from '../../Hooks';

const RelatedBlogs = ({ id, setPending }) => {
	const [relatedBlogs, setRelatedBlogs] = useState([]);
	const mobile = useIsMobile();

	useEffect(() => {
		onSnapshot(
			collection(db, 'Blogs'),
			(snapshot) => {
				const blogs = snapshot.docs
					.map((doc) => ({
						...doc.data(),
						id: doc.id,
					}))
					.filter((e) => e.blogId !== id)
					.slice(0, 3);

				setRelatedBlogs(blogs);
			},
			(error) => {
				console.log(error.message);
			}
		);
	}, [id]);

	const handlePending = () => {
		setPending(true);

		setTimeout(() => {
			setPending(false);
			scrollToTop();
		}, 500);
	};

	return (
		<Container mobile={mobile}>
			<StyledH3 margin='0 0 40px 0' marginMobile='0 0 32px 0'>
				Mirá estas otras notas
			</StyledH3>
			<ArticleContainer mobile={mobile}>
				{relatedBlogs.map((elem, i) => (
					<BlogCard key={i} data={elem} onClick={handlePending} />
				))}
			</ArticleContainer>
		</Container>
	);
};

export default RelatedBlogs;

const Container = styled.div`
	width: ${({ mobile }) => (mobile ? '91%' : '82.3%')};
	margin: 64px auto 0 auto;
`;

const ArticleContainer = styled.div`
	width: ${({ mobile }) => (mobile ? '100%' : '100%')};
	margin: 0 auto 0 auto;
	display: flex;
	flex-wrap: ${({ mobile }) => (mobile ? 'wrap' : 'nowrap')};
	justify-content: ${({ mobile }) => (mobile ? 'center' : 'space-between')};
	row-gap: 40px;
`;
