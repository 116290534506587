import Banner from '../../Components/Banner';
import Carrusel from '../../Components/Carrusel';
import DownloadCatalogo from './../../Components/DownloadCatalogo/index';
import { Helmet } from 'react-helmet';
import MarcasExclusivas from '../../Components/MarcasExclusivas';
import NuestrosProductos from '../../Components/Productos';
import React from 'react';
import Ventajas from '../../Components/Ventajas';
import WhatsappIcon from '../../Components/Shared/WhatsappIcon';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useIsMobile } from '../../Hooks';

const HomeContainer = () => {
	const mobile = useIsMobile();
	const backgorundDesktop = './img/backgroundDesktop.png';
	const backgroundMobile = './img/backgroundMobile.png';
	return (
		<>
			<Helmet>
				<title>Home</title>
				<link rel='canonical' href='http://simpleabasto.com.ar' />
				<meta
					name='description'
					content='Nos proponemos simplificar tus compras, unificar tus proveedores. Manejamos actualmente más de 70 proveedores, trabajamos con stock permanente, lo que nos permite dar una rápida y dinámica respuesta a tus necesidades. Venimos a hacerte la vida más Simple.'
				/>
				<meta
					name='keywords'
					content='simple,abasto, distribución,bares, restaurant, restauranes, abasto gastronómico, insumos,insumos bares, pedidos bares, compras, cocinas, swift, hellmans,tregar, canal tradicional, canal gastronómico,  quento, córdoba, argentina'
				/>
			</Helmet>
			<Container
				mobile={mobile}
				background={mobile ? backgroundMobile : backgorundDesktop}>
				<WhatsappIcon />
				<Banner />
				<Carrusel />
				<Ventajas />
				<NuestrosProductos />
				<MarcasExclusivas />
				<DownloadCatalogo />
			</Container>
		</>
	);
};

export default HomeContainer;

const Container = styled.div`
	width: ${({ mobile }) => (mobile ? '100%' : '95%')};
	max-width: 1440px;
	margin: 0 auto;
	padding: 80px 0 0 0;
	background-image: ${({ background }) => background && `url(${background})`};
	background-position: top top;
	background-repeat: no-repeat;
	background-size: contain;
	/* position: relative;
	z-index: ${theme.zIndex.home}; */
`;
