import React from 'react';
import styled from 'styled-components';
import theme from './../../../Constants/theme';
import { useIsMobile } from '../../../Hooks';

const PrimaryButton = (props) => {
	const mobile = useIsMobile();

	const handleClick = (e) => props?.onClick && props.onClick(e);

	return (
		<Container mobile={mobile} onClick={handleClick} {...props}>
			{props.children}
		</Container>
	);
};

export default PrimaryButton;
const Container = styled.button`
	font-family: helvMedium;
	cursor: pointer;
	background-color: ${({ background, disabled, disableBackground }) =>
		background
			? disableBackground
				? `${disableBackground}`
				: `${background}`
			: disabled
			? disableBackground
				? `${disableBackground}`
				: `${theme.colors.lightPrimary}`
			: `${theme.colors.primary}`};
	${({ width, widthMobile, mobile }) =>
		width && (mobile ? `width: ${widthMobile}` : `width: ${width}`)};
	border: none;
	border-radius: 8px;
	padding: ${({ mobile, padding, paddingMobile }) =>
		padding ? (mobile ? `${paddingMobile}` : `${padding}`) : '10px 16px'};
	color: ${({ color }) => (color ? `${color}` : `${theme.colors.white}`)};
	font-size: ${theme.fontSize.desktop.parrafo18};
	${({ alignSelf }) => alignSelf && `align-self: ${alignSelf}`};
	${({ margin }) => margin && `margin: ${margin}`};

	:hover {
		background-color: ${({ backgroundHover, disabled }) =>
			backgroundHover
				? `${backgroundHover}`
				: !disabled
				? `${theme.colors.darkPrimary}`
				: `${theme.colors.lightPrimary}`};
		${({ colorHover }) => colorHover && `color: ${colorHover}`};
	}
`;
