export const navLinks = [
	{ name: 'Ventajas', href: '/ventajas', offset: '0', id: 'Ventajas' },
	{ name: 'Productos', href: '/productos', offset: '0', id: 'Productos' },
	{ name: 'Nuestras Marcas', href: '/marcas', offset: '0', id: 'Marcas' },
	{ name: 'Catálogo', href: '/catalogo', offset: '0', id: 'Catalogo' },
	{ name: 'Nosotros', href: '/nosotros', offset: '0', id: 'Nosotros' },
	{ name: 'Blog', href: '/blogs', offset: '0', id: 'Blogs' },
	{ name: 'Contactar', href: '/contacto', offset: '0', id: 'Contacto' },
];

export const advantages = [
	{
		order: 1,
		name: 'Proveedor integral',
		srcImg:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fventajas1.png?alt=media&token=47bc5a5b-f0be-4930-a6d0-302dc4f02b7a',
		text: 'Amplia oferta de productos y marcas para simplificar tus compras.',
	},
	{
		order: 2,
		name: 'Atención personalizada',
		srcImg:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fventajas2.png?alt=media&token=1615a03d-6819-415f-a787-c1e3aaf1b95d',
		text: 'En Simple trabajamos con ejecutivos de cuenta para responder tus inquietudes.',
	},
	{
		order: 3,
		name: 'Pedidos en 24 hs',
		srcImg:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fventajas3.png?alt=media&token=6e09774c-958a-4897-b1db-180bc1d3ab4a',
		text: 'Tus pedidos en 24 hs o menos de acuerdo a tu necesidad.',
	},
	{
		order: 4,
		name: 'Logística eficiente',
		srcImg:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fventajas4.png?alt=media&token=d83589bb-1a5a-4116-8517-d829e406a234',
		text: 'Cubrimos toda la ciudad de Córdoba y alrededores para enviarte tu compra.',
	},
];

export const categories = [
	{
		order: 1,
		name: 'Almacén',
		srcBackground:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2FAlmacen.png?alt=media&token=a4279520-1dde-4f18-ac5e-a1b7b6edf4da',
	},
	{
		order: 2,
		name: 'Limpieza',
		srcBackground:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2FLimpieza.png?alt=media&token=9ffa2fd3-6f50-448f-8d57-b37e36cb562c',
	},
	{
		order: 3,
		name: 'Quesos',
		srcBackground:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2FQuesos.png?alt=media&token=229cd49c-22c7-4cfe-a057-8d5917880221',
	},
	{
		order: 4,
		name: 'Fiambres',
		srcBackground:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2FFiambres.png?alt=media&token=6c8ddc6d-bb33-4980-a583-2936a878a4e3',
	},
	{
		order: 5,
		name: 'Lácteos',
		srcBackground:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2FLacteos.png?alt=media&token=04a16635-286a-40ca-9a9f-aee175707e02',
	},
	{
		order: 6,
		name: 'Snacks',
		srcBackground:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2FSnacks.png?alt=media&token=84eb6d22-7d01-4de0-ba65-7ee3736a48fb',
	},
];

export const brands = [
	{
		order: 1,
		name: 'tregar',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Ftregar.png?alt=media&token=28ff636b-3ef8-4111-b8e8-474d32511279',
	},
	{
		order: 2,
		name: 'milkaut',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fmilkaut.png?alt=media&token=c5850751-5005-494f-a9e1-805f8f6e6477',
	},
	{
		order: 3,
		name: 'knorr',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fknorr.png?alt=media&token=fcd850f0-adf7-401b-afae-bb18d70fc149',
	},
	{
		order: 4,
		name: 'hellmans',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fhellmans.png?alt=media&token=e03da88d-138e-40cb-8b9a-e5bfb95f5fd0',
	},
	{
		order: 5,
		name: 'cachamai',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fcachamai.png?alt=media&token=569f82f8-9953-48ff-9e12-b7906f7642e7',
	},
	{
		order: 6,
		name: 'puntaDelAgua',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2FpuntaDelAgua.png?alt=media&token=654389b7-12d7-47a4-8a49-bb8c8f3f2cc9',
	},
	{
		order: 18,
		name: 'cagnoli',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fcagnoli.jpg?alt=media&token=43989970-336f-4e3a-916a-722d2c3be0bf',
	},
	{
		order: 7,
		name: 'quento',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fquento.png?alt=media&token=5dfbb5e4-a19e-436b-91a4-b249c2b70631',
	},
	{
		order: 8,
		name: 'swift',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fswift.png?alt=media&token=f8c1ea6a-4e5d-4098-854f-da035f63fd3f',
	},
	{
		order: 9,
		name: 'bahia',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fbahia.png?alt=media&token=6aaa0b35-8fbe-4cc4-8c73-eaaf28ac7b40',
	},
	{
		order: 10,
		name: 'gracielaReal',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2FgracielaReal.png?alt=media&token=ec3ef88b-86e7-435f-88cb-87a71332f217',
	},
	{
		order: 11,
		name: 'tonadita',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Ftonadita.png?alt=media&token=035f3eee-235d-44a6-8e81-f615302acaa5',
	},
	{
		order: 19,
		name: 'tryms',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Ftryms.png?alt=media&token=3bb4482c-e70e-46af-90c5-38cc64b46918',
	},
	{
		order: 12,
		name: 'indumar',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Findumar.png?alt=media&token=ef6b5871-47a9-49e6-bcfe-1d1d26468998',
	},
	{
		order: 13,
		name: 'laQuesera',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2FlaQuesera.png?alt=media&token=0403ab7a-2dfc-4965-9850-d87043752bb0',
	},
	{
		order: 14,
		name: 'velez',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fvelez.png?alt=media&token=10ad6099-bb70-4e84-9f0f-f2119589a200',
	},
	{
		order: 15,
		name: 'agrosabor',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fagrosabor.png?alt=media&token=39612516-b356-4a89-8e7e-73cd95bbacaf',
	},
	{
		order: 16,
		name: 'savora',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fsavora.png?alt=media&token=2391a89d-9f7e-463e-8b9d-e739c08a2291',
	},
	{
		order: 20,
		name: 'citric',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fcitric.png?alt=media&token=5df82d63-8284-49b7-b1b0-b636a02b7920',
	},
	{
		order: 17,
		name: 'ledesma',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fledesma.png?alt=media&token=40b7c52e-5f6d-457d-b9ed-35a5b462c64b',
	},
	{
		order: 21,
		name: 'arytza',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Farytza.png?alt=media&token=6f67aea3-9bbc-4a70-8f6c-3fd38994e372',
	},
	{
		order: 22,
		name: 'inalpa',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Finalpa.png?alt=media&token=91142cf4-96ba-4eb8-bd15-6971d11fab16',
	},
	{
		order: 23,
		name: 'sanGiorgio',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2FsanGiorgio.png?alt=media&token=17c65c55-3457-487a-8a91-7b9c55e47582',
	},
	{
		order: 24,
		name: 'casalta',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fcasalta.png?alt=media&token=d0f9afa6-0a9f-4772-b07f-952de89c13f7',
	},
	{
		order: 25,
		name: 'salentein',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fsalentein.png?alt=media&token=308b72f9-4507-43e8-a387-1baeddad1ca0',
	},
	{
		order: 26,
		name: 'callia',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fcallia.png?alt=media&token=5131125b-9eb7-4972-8595-de6f48e783df',
	},
	{
		order: 27,
		name: 'portillo',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fportillo.png?alt=media&token=92e8efb6-c5f8-49cc-ba9d-41667d2a3dbd',
	},
	{
		order: 28,
		name: 'morixe',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fmorixe.png?alt=media&token=1b191999-90b3-4005-bc7e-891bf5e5adb7',
	},
	{
		order: 29,
		name: 'lario',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Flario.png?alt=media&token=7d5d67af-d418-4c7c-a796-a71b7c283a40',
	},
];

export const homeBrands = [
	{
		order: 1,
		name: 'tregar',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Ftregar.png?alt=media&token=28ff636b-3ef8-4111-b8e8-474d32511279',
	},
	{
		order: 2,
		name: 'puntaDelAgua',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2FpuntaDelAgua.png?alt=media&token=654389b7-12d7-47a4-8a49-bb8c8f3f2cc9',
	},
	{
		order: 3,
		name: 'knorr',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fknorr.png?alt=media&token=fcd850f0-adf7-401b-afae-bb18d70fc149',
	},
	{
		order: 4,
		name: 'cagnoli',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fcagnoli.jpg?alt=media&token=43989970-336f-4e3a-916a-722d2c3be0bf',
	},
	{
		order: 5,
		name: 'milkaut',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fmilkaut.png?alt=media&token=c5850751-5005-494f-a9e1-805f8f6e6477',
	},
	{
		order: 6,
		name: 'cachamai',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fcachamai.png?alt=media&token=569f82f8-9953-48ff-9e12-b7906f7642e7',
	},
	{
		order: 7,
		name: 'hellmans',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fhellmans.png?alt=media&token=e03da88d-138e-40cb-8b9a-e5bfb95f5fd0',
	},
	{
		order: 8,
		name: 'swift',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fswift.png?alt=media&token=f8c1ea6a-4e5d-4098-854f-da035f63fd3f',
	},
	{
		order: 9,
		name: 'quento',
		background:
			'https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fmarcas%2Fquento.png?alt=media&token=5dfbb5e4-a19e-436b-91a4-b249c2b70631',
	},
];

export const selectOptions = [
	{ order: 2, name: 'Centro de producción', value: 'centroDeProd' },
	{ order: 3, name: 'Local gastronómico', value: 'local' },
	{ order: 4, name: 'Supermercado', value: 'super' },
	{ order: 5, name: 'Autoservicio', value: 'autoserv' },
	{ order: 6, name: 'Almacén', value: 'almacen' },
	{ order: 7, name: 'Otro', value: 'otro' },
];

export const blogCategories = [
	{ order: 1, name: 'Sustentabilidad', href: 'sustentabilidad' },
	{ order: 2, name: 'Eventos', href: 'eventos' },
	{ order: 3, name: 'Novedades Simple', href: 'novedades_simple' },
	{ order: 4, name: 'Nuevos productos', href: 'nuevos_productos' },
];

export const sideMenuLinks = [
	{ menuName: 'Inicio', id: 1, isShown: true, url: '/admin/inicio' },
	{ menuName: 'Blogs', id: 2, isShown: true, url: '/admin/blogs' },
];
export const blogFields = [
	{
		name: 'category',
		inputLabel: 'Categoría',
		options: blogCategories.map((e, i) => {
			return { label: e.name, value: e.href };
		}),
		isRequired: true,
		type: 'select',
		id: 1,
		orderNumber: 0,
	},
	{
		name: 'title',
		inputLabel: 'Título principal',
		isRequired: true,
		type: 'text',
		id: 2,
		orderNumber: 1,
	},
	{
		name: 'img',
		inputLabel: 'Imagen',
		isRequired: true,
		type: 'img',
		id: 3,
		orderNumber: 2,
	},
	{
		name: 'body',
		inputLabel: 'Cuerpo principal',
		isRequired: true,
		type: 'textArea',
		id: 4,
		orderNumber: 3,
	},
	{
		name: 'subTitle1',
		inputLabel: 'Subtítulo 1',
		isRequired: false,
		type: 'text',
		id: 5,
		orderNumber: 4,
	},
	{
		name: 'secundaryBody',
		inputLabel: 'Párrafo 1',
		isRequired: false,
		type: 'textArea',
		id: 6,
		orderNumber: 5,
	},
	{
		name: 'subTitle2',
		inputLabel: 'Subtítulo 2',
		isRequired: false,
		type: 'text',
		id: 7,
		orderNumber: 6,
	},
	{
		name: 'secundaryBody2',
		inputLabel: 'Párrafo 2',
		isRequired: false,
		type: 'textArea',
		id: 8,
		orderNumber: 7,
	},
	{
		name: 'subTitle3',
		inputLabel: 'Subtítulo 3',
		isRequired: false,
		type: 'text',
		id: 9,
		orderNumber: 8,
	},
	{
		name: 'secundaryBody3',
		inputLabel: 'Párrafo 3',
		isRequired: false,
		type: 'textArea',
		id: 10,
		orderNumber: 9,
	},
];

export const scrollTo = (location, navigate, route, name) => {
	if (route === '/nuestras-marcas') {
		setTimeout(() => {
			navigate(route);
			window.scrollTo({ behavior: 'smooth', top: 0 });
		}, 200);
	}
	if (route === '/nosotros') {
		if (location !== 'nosotros')
			setTimeout(() => {
				navigate(route);
				window.scrollTo({ behavior: 'smooth', top: 0 });
			}, 200);
		else {
			setTimeout(() => {
				window.scrollTo({ behavior: 'smooth', top: 0 });
			}, 200);
		}
	}
	if (route === '/blogs') {
		if (location !== 'blogs')
			setTimeout(() => {
				navigate(route);
				window.scrollTo({ behavior: 'smooth', top: 0 });
			}, 200);
		else {
			setTimeout(() => {
				window.scrollTo({ behavior: 'smooth', top: 0 });
			}, 200);
		}
	}

	if (route === '/') {
		setTimeout(() => {
			navigate('/');
		}, 200);

		setTimeout(() => {
			window.scrollTo({ behavior: 'smooth', top: 0 });
		}, 500);
	}

	if (
		route === '/marcas' ||
		route === '/contacto' ||
		route === '/productos' ||
		route === '/catalogo' ||
		route === '/ventajas'
	) {
		if (location === '/') {
			setTimeout(() => {
				document.getElementById(`${name}`).scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});
			}, 200);
		} else {
			setTimeout(() => {
				navigate('/');
			}, 200);
			setTimeout(() => {
				document.getElementById(`${name}`).scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest',
				});
			}, 700);
		}
	}
};

export function scrollToTop() {
	window.scrollTo({ behavior: 'smooth', top: 0 });
}
