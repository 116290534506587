import PrimaryButton from '../../Shared/PrimaryButton';
import React from 'react';
import { auth } from '../../../Firebase';
import { sideMenuLinks } from '../../../Utils';
import styled from 'styled-components';
import theme from '../../../Constants/theme';
import { useNavigate } from 'react-router-dom';

const SideMenu = ({ handleClick, selectedOption, selectedIndex }) => {
	const navigate = useNavigate();
	const handleLogout = () => {
		auth.signOut();
		navigate('/admin/login');
	};
	return (
		<Container>
			<LinkContainer>
				{sideMenuLinks.map((e, i) => (
					<StyledLink
						onClick={() => handleClick(i, e.menuName, e.url)}
						key={i}
						isActive={e.menuName === selectedOption}>
						{e.menuName}
					</StyledLink>
				))}
			</LinkContainer>
			<PrimaryButton
				onClick={handleLogout}
				color={`${theme.colors.primary}`}
				background={`${theme.colors.white}`}
				backgroundHover={`${theme.colors.ligthGrey}`}
				margin='0 0 20px 0'>
				Cerrar sesión
			</PrimaryButton>
		</Container>
	);
};

export default SideMenu;

const Container = styled.div`
	width: 20%;
	height: 100vh;
	background-color: ${theme.colors.primary};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
const LinkContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1 1;
`;
const StyledLink = styled.button`
	font-family: 'helvMedium';

	font-weight: ${({ isActive }) => (isActive ? 'bold' : 'init')};
	font-size: ${theme.fontSize.desktop.h3};
	border: none;
	background-color: transparent;
	color: ${theme.colors.white};
`;
