import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db, storage } from '../../Firebase';
import { getDownloadURL, ref } from 'firebase/storage';

import { Helmet } from 'react-helmet';
import Loader from '../../Components/Shared/Loader';
import MoreCategories from '../../Components/MoreCategories';
import RelatedBlogs from '../../Components/RelatedBlogs';
import StyledH2 from './../../Components/Shared/StyledH2/index';
import StyledH3 from '../../Components/Shared/StyledH3';
import StyledParagraph16 from './../../Components/Shared/StyledParagraph16/index';
import WhatsappIcon from '../../Components/Shared/WhatsappIcon';
import { blogCategories } from '../../Utils';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useIsMobile } from '../../Hooks';
import { useParams } from 'react-router-dom';

const BlogDetailContainer = () => {
	const [pending, setPending] = useState(true);
	const [blog, setBlog] = useState(null);
	const [url, setUrl] = useState(null);
	const { blogId } = useParams();
	const moreCategories = blogCategories.slice(0);
	const mobile = useIsMobile();

	const loaderContainer = {
		width: '100%',
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	};

	useEffect(() => {
		const q = query(
			collection(db, 'Blogs'),
			where('blogId', '==', `${blogId}`)
		);
		onSnapshot(
			q,
			(snapshot) => {
				const blog = snapshot.docs.map((doc) => ({
					...doc.data(),
					id: doc.id,
				}));
				setBlog(blog);
			},

			(error) => console.log('error', error)
		);
	}, [blogId]);

	useEffect(() => {
		blog &&
			blog[0] &&
			getDownloadURL(ref(storage, `Blogs/${blog[0]?.img}`))
				.then((url) => {
					setUrl(url);
				})
				.catch((error) => {
					console.log(error.msg);
				})
				.finally(() => {
					setPending(false);
				});
	}, [blog]);

	return (
		<>
			{pending ? (
				<div style={loaderContainer}>
					<Loader />
				</div>
			) : (
				<>
					<Helmet>
						<title>{blog && blog[0] && blog[0]?.title}</title>
						<link
							rel='canonical'
							href={`http://simpleabasto.com.ar/blogs/detalles`}
						/>
						<meta
							name='description'
							content='Desde el año 2014, brindamos desarrollo, representación y comercialización de marcas de retail, basados en el servicio y la satisfacción del cliente. Nos proponemos simplificar tus compras y unificar tus proveedores. Trabajamos con stock permanente, lo que nos permite dar una rápida y dinámica respuesta a tus necesidades. Venimos a hacerte la vida más Simple.'
						/>
						<meta
							name='keywords'
							content='representación, comercialización, retail, simple,abasto, distribución,bares, restaurant, restauranes, abasto gastronómico, insumos,insumos bares, pedidos bares, compras, cocinas, swift, hellmans,tregar, canal tradicional, canal gastronómico,  quento, córdoba, argentina'
						/>
					</Helmet>
					<Container mobile={mobile}>
						<WhatsappIcon />
						<InfoContainer mobile={mobile}>
							<StyledH2 margin={'0 0 40px 0'} marginMobile='0 0 32px 0'>
								{blog && blog[0]?.title}
							</StyledH2>
							<Img mobile={mobile} width='300' src={url} />
							<MainInformation>
								<StyledParagraph16 fontFamily='helvMedium'>
									{blog && blog[0]?.body}
								</StyledParagraph16>
							</MainInformation>
							<SecundaryInformation>
								{blog && blog[0]?.subTitle1 && blog[0]?.secundaryBody && (
									<TitleContainer>
										<StyledH3>{blog[0].subTitle1}</StyledH3>
										<StyledParagraph16 fontFamily='helvMedium'>
											{blog[0].secundaryBody}
										</StyledParagraph16>
									</TitleContainer>
								)}
								{blog && blog[0]?.subTitle2 && blog[0]?.secundaryBody2 && (
									<TitleContainer>
										<StyledH3>{blog[0].subTitle2}</StyledH3>
										<StyledParagraph16 fontFamily='helvMedium'>
											{blog[0].secundaryBody2}
										</StyledParagraph16>
									</TitleContainer>
								)}
								{blog && blog[0]?.subTitle3 && blog[0]?.secundaryBody3 && (
									<TitleContainer>
										<StyledH3>{blog[0].secundaryBody3}</StyledH3>
										<StyledParagraph16 fontFamily='helvMedium'>
											{blog[0].secundaryBody3}
										</StyledParagraph16>
									</TitleContainer>
								)}
							</SecundaryInformation>
						</InfoContainer>
						<RelatedBlogs id={blogId} setPending={setPending} />
						<MoreCategories categories={moreCategories} />
					</Container>
				</>
			)}
		</>
	);
};

export default BlogDetailContainer;

const Container = styled.div`
	padding: ${({ mobile }) =>
		mobile
			? `${theme.paddingVertical.mobile.blog}`
			: `${theme.paddingVertical.desktop.blog}`};
	width: ${({ mobile }) => (mobile ? '100%' : '95%')};
	max-width: 1440px;
	margin: 0 auto;
`;
const InfoContainer = styled.div`
	width: ${({ mobile }) => (mobile ? '90%' : '54%')};
	margin: 0 auto;
`;

const Img = styled.img`
	width: 100%;
	height: ${({ mobile }) => (mobile ? '185px' : '439px')};
	object-fit: cover;
	object-position: center;
	margin: 0 0 0 0;
`;

const MainInformation = styled.div`
	margin: 40px 0 0 0;
`;
const SecundaryInformation = styled.div`
	margin: 40px 0 0 0;
	p {
		${({ mobile }) => mobile && 'margin: 0 0 40px 0'};
	}
	h3 {
		${({ mobile }) => mobile && 'margin: 0 0 16px 0'};
	}
`;
const TitleContainer = styled.div``;
