import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

import BlogCard from './../../Components/BlogCard/index';
import CategoryCard from './../../Components/CategoryCard/index';
import { Helmet } from 'react-helmet';
import Loader from '../../Components/Shared/Loader';
import MoreCategories from './../../Components/MoreCategories/index';
import Paginator from './../../Components/Paginator/index';
import StyledH2 from '../../Components/Shared/StyledH2';
import StyledParagraph16 from '../../Components/Shared/StyledParagraph16';
import StyledParagraph18 from './../../Components/Shared/StyledParagraph18/index';
import WhatsappIcon from '../../Components/Shared/WhatsappIcon';
import { blogCategories } from './../../Utils/index';
import { db } from '../../Firebase';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useIsMobile } from '../../Hooks';
import { useParams } from 'react-router-dom';

const BlogContainer = () => {
	const mobile = useIsMobile();
	const [pending, setPending] = useState(true);
	const [blogs, setBlogs] = useState([]);
	const [otherCategories, setOtherCategories] = useState([]);
	const { category } = useParams();
	const blogTitle = category && category.replace('_', ' ');
	const [currentPage, setCurrentPage] = useState(1);
	const [blogsPerPage, setBlogsPerPage] = useState(6); //eslint-disable-line
	const [currentBlogs, setCurrentBlogs] = useState([]);

	useEffect(() => {
		if (category) {
			const q = query(
				collection(db, 'Blogs'),
				where('category', '==', `${category}`)
			);
			onSnapshot(
				q,
				(snapshot) => {
					const blogList = snapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
					}));
					setPending(false);
					setBlogs(blogList);
				},
				(error) => console.log('error', error)
			);
		} else {
			onSnapshot(
				collection(db, 'Blogs'),
				(snapshot) => {
					const blogList = snapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
					}));
					setPending(false);
					setBlogs(blogList);
				},
				(error) => console.log('error', error)
			);
		}

		category &&
			setOtherCategories(
				blogCategories.filter(
					(e) => e.name.toLowerCase() !== category.replace('_', ' ')
				)
			);
	}, [category]);

	useEffect(() => {
		const indexOfLastBlog = currentPage * blogsPerPage;
		const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
		setCurrentBlogs(blogs.slice(indexOfFirstBlog, indexOfLastBlog));
	}, [blogs, blogsPerPage, currentPage]);

	const changePage = (e) => {
		if (e.target.className.includes('anterior')) {
			setCurrentPage(currentPage - 1);
		} else {
			setCurrentPage(currentPage + 1);
		}
	};

	const totalPages = Math.ceil(blogs.length / blogsPerPage);

	const resetPage = () => {
		setCurrentPage(1);
	};

	return (
		<>
			<Helmet>
				<title>Blogs</title>
				<link
					rel='canonical'
					href={`http://simpleabasto.com.ar/blogs/${category}`}
				/>
				<meta
					name='description'
					content='Desde el año 2014, brindamos desarrollo, representación y comercialización de marcas de retail, basados en el servicio y la satisfacción del cliente. Nos proponemos simplificar tus compras y unificar tus proveedores. Trabajamos con stock permanente, lo que nos permite dar una rápida y dinámica respuesta a tus necesidades. Venimos a hacerte la vida más Simple.'
				/>
				<meta
					name='keywords'
					content='representación, comercialización, retail, simple,abasto, distribución,bares, restaurant, restauranes, abasto gastronómico, insumos,insumos bares, pedidos bares, compras, cocinas, swift, hellmans,tregar, canal tradicional, canal gastronómico,  quento, córdoba, argentina'
				/>
			</Helmet>
			<Container mobile={mobile}>
				<WhatsappIcon />
				<StyledH2
					mobile={mobile}
					id='Blogs'
					style={{ textTransform: 'capitalize' }}
					align='center'
					alignMobile='center'
					margin={
						!category
							? `${theme.marginVertical.desktop.h2Nosotros}`
							: '0 0 90px 0'
					}
					marginMobile={`${theme.marginVertical.mobile.h2Nosotros}`}>
					{category ? blogTitle : 'Blog'}
				</StyledH2>
				{!category && (
					<StyledParagraph18
						fontFamily='helvMedium'
						align='center'
						alignMobile='center'
						margin={`${theme.marginVertical.desktop.h1}`}
						marginMobile={`${theme.marginVertical.mobile.h2Nosotros}`}>
						Encontrá las últimas novedades y noticias
					</StyledParagraph18>
				)}
				{!category && (
					<CategoryContainer mobile={mobile}>
						{blogCategories.map((e) => (
							<CategoryCard key={e.order} data={e} onClick={resetPage} />
						))}
					</CategoryContainer>
				)}
				<ArticleContainer mobile={mobile} empty={blogs.length === 0}>
					{!pending ? (
						currentBlogs.length !== 0 ? (
							currentBlogs.map((elem, i) => {
								return <BlogCard key={i} data={elem} />;
							})
						) : (
							<StyledParagraph16>
								No existen blogs para esta categoría
							</StyledParagraph16>
						)
					) : (
						<Loader />
					)}
				</ArticleContainer>
				{currentBlogs.length !== 0 && (
					<Paginator
						totalPages={totalPages}
						changePage={changePage}
						currentPage={currentPage}
					/>
				)}
				{category && (
					<MoreCategories categories={otherCategories} resetPage={resetPage} />
				)}
			</Container>
		</>
	);
};

export default BlogContainer;
const Container = styled.div`
	padding: ${({ mobile }) =>
		mobile
			? `${theme.paddingVertical.mobile.blog}`
			: `${theme.paddingVertical.desktop.blog}`};
	width: ${({ mobile }) => (mobile ? '100%' : '95%')};
	max-width: 1440px;
	margin: 0 auto;
`;

const CategoryContainer = styled.div`
	width: ${({ mobile }) => (mobile ? '91%' : '82.3%')};
	margin: ${({ mobile }) =>
		mobile ? '0 auto 40px auto' : '0 auto 104px auto'};
	display: flex;
	flex-direction: ${({ mobile }) => (mobile ? 'column' : 'row')};
	flex-wrap: wrap;
	${({ mobile }) => mobile && 'align-items:center'};
	${({ mobile }) => mobile && 'row-gap: 16px'};
	justify-content: space-between;
`;

const ArticleContainer = styled.div`
	width: ${({ mobile }) => (mobile ? '91%' : '82.3%')};
	height: ${({ empty }) => (empty ? '504px' : 'auto')};
	margin: 0 auto 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: ${({ empty, mobile }) =>
		empty || mobile ? 'center' : 'space-between'};
	align-items: ${({ empty, mobile }) => (empty ? 'center' : 'flex-start')};
	row-gap: 40px;
`;

const Title = styled.title`
	text-transform: capitalize;
`;
