import React, { useMemo } from 'react';

import styled from 'styled-components';
import { useIsMobile } from '../../Hooks/index';

const IntroducingVideo = () => {
	const mobile = useIsMobile();

	const video = useMemo(
		() => (
			<Video controls muted>
				<source
					src='https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fvideo%2FsimpleVideo.mp4?alt=media&token=49efafeb-e5e3-4e1e-aa46-bd6d1f5a9928'
					type='video/mp4'
				/>
			</Video>
		),
		[]
	);

	return <Container mobile={mobile}>{video}</Container>;
};

export default IntroducingVideo;

const Container = styled.div`
	width: ${({ mobile }) => (mobile ? '100%' : '57.5%')};
`;
const Video = styled.video`
	width: 100%;
`;
