import CategoryCard from './../CategoryCard/index';
import React from 'react';
import StyledParagraph18 from './../Shared/StyledParagraph18/index';
import styled from 'styled-components';
import { useIsMobile } from '../../Hooks';

const MoreCategories = ({ categories, resetPage }) => {
	const mobile = useIsMobile();
	return (
		<OtherCategoryContainer>
			<StyledParagraph18 fontFamily='helvMedium' margin='0 0 24px 0'>
				Encontrá las últimas novedades y noticias
			</StyledParagraph18>
			<Box mobile={mobile}>
				{categories.map((e) => (
					<CategoryCard key={e.order} data={e} onClick={resetPage} />
				))}
			</Box>
		</OtherCategoryContainer>
	);
};

export default MoreCategories;

const OtherCategoryContainer = styled.div`
	width: ${({ mobile }) => (mobile ? '91%' : '82.3%')};
	margin: 82px auto 0 auto;
`;

const Box = styled.div`
	display: flex;
	flex-direction: ${({ mobile }) => (mobile ? 'column' : 'row')};
	justify-content: ${({ mobile }) => (mobile ? 'center' : 'start')};
	${({ mobile }) => mobile && 'align-items: center'};
	${({ mobile }) => mobile && 'row-gap: 16px'};

	column-gap: 32px;
`;
