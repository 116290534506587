import React, { useReducer, useRef, useState } from 'react';
import {
	formReducer,
	initialState,
	onFocusOut,
} from './../../../Utils/formValidations';

import PrimaryButton from '../PrimaryButton';
import Select from 'react-select';
import StyledH1 from './../StyledH1/index';
import StyledH2 from './../StyledH2/index';
import StyledParagraph18 from './../StyledParagraph18/index';
import emailjs from '@emailjs/browser';
import { onInputChange } from '../../../Utils/formValidations';
import { selectOptions } from '../../../Utils';
import styled from 'styled-components';
import theme from './../../../Constants/theme';
import { useIsFormDisabled } from './../../../Hooks/index';
import { useIsMobile } from '../../../Hooks';

const ContactForm = () => {
	const mobile = useIsMobile();
	const form = useRef(null);
	const [formState, dispatchFormState] = useReducer(formReducer, initialState);
	const [emailSent, setEmailSent] = useState(false);
	const [pending, setPending] = useState(false);

	const handleChange = (name, value) => {
		onInputChange(name, value, dispatchFormState, formState);
	};

	const handleBlur = (name, value) => {
		name !== 'tipoLocal' &&
			!mobile &&
			onFocusOut(name, value, dispatchFormState, formState);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setPending(true);
		emailjs
			.sendForm(
				'service_8xvt7l5',
				'template_ujr91x5',
				form.current,
				'ztC2KEVww-is8_TJV'
			)
			.then(
				(result) => {
					setPending(false);
				},
				(error) => {
					console.log(error.text);
				}
			)
			.finally(() => {
				setEmailSent(true);
			});
	};
	const handleClick = () => {
		document.location.reload();
	};

	const options = selectOptions.map((elem) => {
		return { value: `${elem.value}`, label: `${elem.name}` };
	});

	const customStylesDesktop = {
		control: (provided, state) => ({
			...provided,

			borderColor: state.isFocused
				? `${theme.colors.grey}`
				: `${theme.colors.grey}`,

			borderRadius: 8,
			boxShadow: state.isfocused && 'none',
		}),
		valueContainer: (provided) => ({
			...provided,
			padding: '0.45rem 1rem',
			border: 'unset',
		}),
		placeholder: (provided) => ({
			...provided,
			color: `${theme.colors.grey}`,
			fontFamily: 'helvMedium',
			fontSize: `${theme.fontSize.desktop.parrafo16}`,
			lineHeight: `${theme.lineHeigth.desktop.parrafo16}`,
		}),
		singleValue: (provided) => ({
			...provided,
			fontFamily: 'helvMedium',
			fontSize: `${theme.fontSize.desktop.parrafo16}`,
			lineHeight: `${theme.lineHeigth.desktop.parrafo16}`,
			color: `${theme.colors.darkGrey}`,
		}),
		menu: (provided) => ({
			...provided,
			borderRadius: 8,
			border: `1px solid ${theme.colors.grey}`,
		}),
		option: (provided, state) => ({
			...provided,
			cursor: 'pointer',
			width: '94%',
			fontFamily: 'helvMedium',
			fontSize: `${theme.fontSize.desktop.parrafo16}`,
			lineHeight: `${theme.lineHeigth.desktop.parrafo16}`,
			color: state.isSelected
				? `${theme.colors.white}`
				: `${theme.colors.darkGrey}`,
			margin: '0 3%',
			padding: 8,
			borderBottom: `1px solid ${theme.colors.grey}`,
			background: state.isSelected
				? `${theme.colors.darkPrimary}`
				: state.isFocused && `${theme.colors.ligthGrey}`,
		}),
		indicatorSeparator: (provided) => ({ ...provided, display: 'none' }),
	};

	const { dashboard, login, blog, notFound } = useIsFormDisabled();

	return (
		<>
			{!login && !dashboard && !blog && !notFound && (
				<Container isLogin={login} isDashboard={dashboard}>
					<Box mobile={mobile}>
						<StyledH2
							id='Contacto'
							mobile={mobile}
							align='center'
							alignMobile='center'
							width='55%'
							margin={`0 auto ${theme.marginVertical.desktop.h2Form} auto`}
							marginMobile={`0 auto ${theme.marginVertical.mobile.h2Form} auto`}>
							¡Queremos hacerte la vida más Simple! Contactanos
						</StyledH2>
						<StyledForm
							mobile={mobile}
							onSubmit={handleSubmit}
							ref={form}
							isSent={emailSent}>
							{emailSent ? (
								<>
									<StyledH1 align='center' color={`${theme.colors.primary}`}>
										Gracias
									</StyledH1>
									<StyledParagraph18
										align='center'
										color={`${theme.colors.primary}`}
										width='65%'
										margin='0 auto 116px auto'>
										Tu mensaje fue envíado correctamente. Un asesor se
										comunicará a la brevedad
									</StyledParagraph18>
								</>
							) : (
								<>
									<InputContainer mobile={mobile}>
										<StyledLabel>Nombre y Apellido</StyledLabel>
										<StyledInput
											name='nombre'
											type='text'
											onChange={(e) =>
												handleChange(e.target.name, e.target.value)
											}
											onBlur={(e) => handleBlur(e.target.name, e.target.value)}
										/>
										<ErrorMsg>
											{formState.nombre.hasError &&
												formState.nombre.error &&
												formState.nombre.error}
										</ErrorMsg>
									</InputContainer>
									<InputContainer mobile={mobile}>
										<StyledLabel>Celular</StyledLabel>
										<StyledInput
											name='telefono'
											type='text'
											onChange={(e) =>
												handleChange(e.target.name, e.target.value)
											}
											onBlur={(e) => handleBlur(e.target.name, e.target.value)}
										/>
										<ErrorMsg>
											{formState.telefono.hasError &&
												formState.telefono.error &&
												formState.telefono.error}
										</ErrorMsg>
									</InputContainer>
									<InputContainer mobile={mobile}>
										<StyledLabel>Correo</StyledLabel>
										<StyledInput
											name='email'
											type='email'
											onChange={(e) =>
												handleChange(e.target.name, e.target.value)
											}
											onBlur={(e) => handleBlur(e.target.name, e.target.value)}
										/>
										<ErrorMsg>
											{formState.email.hasError &&
												formState.email.error &&
												formState.email.error}
										</ErrorMsg>
									</InputContainer>
									<InputContainer mobile={mobile} select={true}>
										<StyledLabel>Tipo de local</StyledLabel>
										<Select
											name='tipoLocal'
											placeholder='Elige una categoria'
											options={options}
											styles={customStylesDesktop}
											classNamePrefix='react-select'
											isSearchable={false}
											onChange={(e) => handleChange('tipoLocal', e.label)}
											onBlur={() => {
												handleBlur('tipoLocal', formState.tipoLocal.value);
											}}
										/>
										<ErrorMsg>
											{formState.tipoLocal.hasError &&
												formState.tipoLocal.error &&
												formState.tipoLocal.touched &&
												formState.tipoLocal.error}
										</ErrorMsg>
									</InputContainer>
									<InputContainer mobile={mobile} textArea={true}>
										<StyledLabel>Consulta</StyledLabel>
										<StyledTextArea
											rows='4'
											name='consulta'
											onChange={(e) =>
												handleChange(e.target.name, e.target.value)
											}
											onBlur={() =>
												handleBlur('consulta', formState.consulta.value)
											}></StyledTextArea>
									</InputContainer>
								</>
							)}

							{emailSent ? (
								<PrimaryButton onClick={handleClick} margin='0 auto'>
									Enviar otro mensaje
								</PrimaryButton>
							) : (
								<PrimaryButton
									disabled={
										!formState.isFormValid ||
										formState.nombre.value === '' ||
										formState.email.value === '' ||
										formState.telefono.value === '' ||
										!mobile & (formState.tipoLocal.value === '') ||
										formState.consulta.value === ''
									}
									margin='0 auto'>
									{!pending ? 'Enviar' : 'Enviando...'}
								</PrimaryButton>
							)}
						</StyledForm>
					</Box>
					<Background mobile={mobile}></Background>
				</Container>
			)}
		</>
	);
};

export default ContactForm;

const Container = styled.div``;
const Box = styled.div`
	width: ${({ mobile }) => (mobile ? '91%' : '95%')};
	max-width: 1440px;
	margin: 0 auto;
`;
const Background = styled.div`
	height: ${({ mobile }) => (mobile ? '26rem' : '16.25rem')};
	background-color: ${theme.colors.darkPrimary};
`;
const StyledForm = styled.form`
	max-width: 684px;
	width: ${({ mobile }) => (mobile ? '100%' : '95%')};
	height: ${({ mobile }) => (mobile ? 'auto' : '538px')};
	padding: ${({ mobile }) => (mobile ? '14.1% 9.5%' : '3.9% 5% 2.8% 5%')};
	margin: ${({ mobile }) =>
		mobile ? '0 auto -360px auto' : '0 auto -260px auto'};
	display: flex;
	column-gap: 20px;
	row-gap: ${({ mobile }) => (mobile ? '10px' : '14px')};
	flex-wrap: wrap;
	flex-direction: ${({ isSent }) => (isSent ? 'column' : 'row')};
	${({ isSent }) => isSent && 'justify-content: flex-end'};
	box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.16);
	border-radius: 32px;
	background-color: ${theme.colors.white};

	.react-select__control:hover {
		border-color: ${theme.colors.grey};
	}

	position: relative;
	z-index: ${theme.zIndex.form};
`;
const StyledInput = styled.input`
	width: 100%;
	font-family: helvMedium;
	font-size: ${theme.fontSize.desktop.parrafo16};
	line-height: ${theme.lineHeigth.desktop.parrafo16};
	color: ${theme.colors.darkGrey};
	padding: 0.45rem 1rem;
	border-radius: 8px;
	border: 1px solid ${theme.colors.grey};
	:focus {
		outline: 0;
	}
`;

const StyledTextArea = styled.textarea`
	width: 100%;
	font-family: helvMedium;
	font-size: ${theme.fontSize.desktop.parrafo16};
	line-height: ${theme.lineHeigth.desktop.parrafo16};
	border-radius: 8px;
	border: 1px solid ${theme.colors.grey};
	padding: 0.45rem 1rem;
	:focus {
		outline: 0;
	}
`;

const StyledLabel = styled.label`
	font-family: helvMedium;
	font-size: ${theme.fontSize.desktop.parrafo18};
	line-height: ${theme.lineHeigth.desktop.parrafo18};
	margin-bottom: 0.45rem;
	color: ${theme.colors.darkGrey};
`;
const InputContainer = styled.div`
	width: ${({ textArea, mobile }) =>
		mobile ? '100%' : textArea ? '100%' : '48%'};
	${({ textArea }) => !textArea && 'height: 97px'};
`;

const ErrorMsg = styled.span`
	font-family: helvRegular;
	font-size: 0.875rem;
	line-height: 1.25rem;
	color: ${theme.colors.primary};
	width: 100%;
	height: 3rem;
`;
