import { navLinks, scrollTo } from './../../Utils/index';
import { useLocation, useNavigate } from 'react-router-dom';

import { GiHamburgerMenu } from 'react-icons/gi';
import React from 'react';
import StyledParagraph16 from './../Shared/StyledParagraph16/index';
import { VscChromeClose } from 'react-icons/vsc';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useWindowSize } from './../../Hooks/index';

const BurgerMenu = ({ menuOpen, setMenuOpen }) => {
	const { height } = useWindowSize();
	const location = useLocation().pathname;
	const navigate = useNavigate();

	const menuHeight = height - 51;

	const handleClick = () => {
		setMenuOpen(!menuOpen);
	};

	const handleGoTo = (ref, name) => {
		setMenuOpen(false);
		scrollTo(location, navigate, ref, name);
	};

	return (
		<>
			<Container onClick={handleClick}>
				{menuOpen ? (
					<>
						<VscChromeClose
							stroke-width='0.7'
							size={23}
							color={`${theme.colors.darkGrey}`}
						/>
					</>
				) : (
					<GiHamburgerMenu size={23} color={`${theme.colors.darkGrey}`} />
				)}
			</Container>
			{menuOpen && (
				<MobileLinkContainer menuHeight={menuHeight}>
					{navLinks.map((e, i) => (
						<CenterLink
							menuHeight={menuHeight}
							contacto={e.name === 'Contactar'}
							key={i}
							onClick={() => handleGoTo(e.href, e.id)}>
							<StyledParagraph16
								fontFamily={e.name === 'Contactar' ? 'helvBlack' : undefined}
								marginMobile='1rem 0 4.85% 1.5rem'
								color={`${theme.colors.white}`}>
								{e.name}
							</StyledParagraph16>
						</CenterLink>
					))}
				</MobileLinkContainer>
			)}
		</>
	);
};

export default BurgerMenu;

const Container = styled.div`
	margin: 0 4.5% 0 0;
	.closeIcon path {
		color: ${theme.colors.darkGrey};
	}
	position: relative;
	z-index: 5;
`;

const MobileLinkContainer = styled.div`
	position: absolute;
	left: 0;
	top: 51px;
	z-index: 1;
	height: ${({ menuHeight }) => (menuHeight ? `${menuHeight}px` : '80vh')};
	min-height: 400px;
	width: 100%;
	padding: 76.84px 4% 0 4%;
	background-color: ${theme.colors.darkPrimary};
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const CenterLink = styled.div`
	${({ contacto, menuHeight }) =>
		!contacto
			? `border-bottom: 1px solid ${theme.colors.lightPrimary};`
			: `margin-top: ${menuHeight / 10}px`};
`;
