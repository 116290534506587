import PrimaryButton from './../Shared/PrimaryButton/index';
import React from 'react';
import StyledParagraph18 from '../Shared/StyledParagraph18';
import styled from 'styled-components';
import theme from './../../Constants/theme';
import { useIsMobile } from '../../Hooks/index';

const Paginator = ({ totalPages, changePage, currentPage }) => {
	const mobile = useIsMobile();

	return (
		<Container mobile={mobile && mobile}>
			<PrimaryButton
				className={currentPage === 1 ? 'anterior hidden' : 'anterior'}
				onClick={changePage}>
				{'<'} Anterior
			</PrimaryButton>
			<StyledParagraph18
				fontFamily='helvMedium'
				color={`${theme.colors.primary}`}
				margin='0'
				marginMobile='0'>
				{`Página ${currentPage} de ${totalPages}`}
			</StyledParagraph18>
			<PrimaryButton
				className={
					totalPages === currentPage ? 'siguiente hidden' : 'siguiente'
				}
				onClick={changePage}>
				Siguiente {'>'}
			</PrimaryButton>
		</Container>
	);
};

export default Paginator;

const Container = styled.div`
	width: ${({ mobile }) => (mobile ? '95%' : '82.3%')};
	margin: 56px auto 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.hidden {
		visibility: hidden;
	}
`;
