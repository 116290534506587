import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AdminSubElements from '../../Components/AdminDashboard/AdminSubElements';
import SideMenu from './../../Components/AdminDashboard/Menú/index';
import styled from 'styled-components';
import theme from './../../Constants/theme';

const Dashboard = () => {
	const [selectedOption, setSelectedOption] = useState('');
	const [selectedIndex, setSelectedIndex] = useState(0);
	const navigate = useNavigate();
	const location = useLocation();

	const handleClick = (index, option, url) => {
		setSelectedOption(option);
		setSelectedIndex(index);
		navigate(url);
	};

	const getSelectedTab = (path) => {
		if (path.includes('admin/inicio')) {
			return 'Inicio';
		} else if (
			path.includes('admin/blogs') ||
			path.includes('blogs/crear-blog') ||
			path.includes('blogs/editar-blog')
		) {
			return 'Blogs';
		}
	};

	useEffect(() => {
		setSelectedOption(getSelectedTab(location.pathname));
	}, [location.pathname]);

	return (
		<Container>
			<AdminContent selectedOption={selectedOption}>
				<SideMenu
					handleClick={handleClick}
					selectedOption={selectedOption}
					selectedIndex={selectedIndex}
				/>
				<AdminSubElements selectedOption={selectedOption} />
			</AdminContent>
		</Container>
	);
};

export default Dashboard;

const Container = styled.div`
	padding: 0 0 0 0;
	/* background-color: ${theme.colors.primary};
	display: flex;
	align-items: center; */
`;

const AdminContent = styled.div`
	display: flex;
`;
