import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import PrimaryButton from './../../Components/Shared/PrimaryButton/index';
import StyledH2 from '../../Components/Shared/StyledH2';
import { auth } from '../../Firebase/index';
import { signInWithEmailAndPassword } from 'firebase/auth';
import styled from 'styled-components';
import theme from './../../Constants/theme';

const Login = ({ currentUser }) => {
	const [user, setUser] = useState({ email: '', password: '' });
	const [errorMessage, setErrorMessage] = useState('');
	useEffect(() => {}, []);

	const handleChange = (name, value) => {
		setErrorMessage('');
		setUser((current) => {
			return { ...current, [name]: value };
		});
	};

	const handleLogin = async (e) => {
		e.preventDefault();
		await signInWithEmailAndPassword(auth, user.email, user.password)
			.then((response) => console.log(response))
			.catch((error) => {
				console.log(error);
				setErrorMessage(
					'Usuario o contraseña erróneo. Vuelve a ingresar los datos'
				);
			});
	};

	return (
		<Container>
			{currentUser ? (
				<Box>
					<StyledH2
						color={`${theme.colors.white}`}
						width='30%'
						align='center'
						margin='30px 0'>
						Sesión iniciada{' '}
					</StyledH2>
					<Link to='/admin/inicio'>Ir al tablero</Link>
				</Box>
			) : (
				<Box>
					<StyledH2
						color={`${theme.colors.white}`}
						width='30%'
						align='center'
						margin='30px 0'>
						Ingresá tu usuario y contraseña
					</StyledH2>
					<StyledForm>
						<InputContainer>
							<StyledLabel>Correo</StyledLabel>
							<StyledInput
								autoComplete='username'
								name='email'
								type='email'
								onChange={(e) => handleChange(e.target.name, e.target.value)}
							/>
							<ErrorMsg>{errorMessage && errorMessage}</ErrorMsg>
						</InputContainer>
						<InputContainer>
							<StyledLabel>Contraseña</StyledLabel>
							<StyledInput
								autoComplete='current-password'
								name='password'
								type='password'
								onChange={(e) => handleChange(e.target.name, e.target.value)}
								maxLength={9}
							/>
						</InputContainer>
						<PrimaryButton
							disabled={
								user.password === '' || user.email === '' ? true : false
							}
							color={`${theme.colors.primary}`}
							background={`${theme.colors.white}`}
							backgroundHover={`${theme.colors.ligthGrey}`}
							disableBackground={`${theme.colors.ligthGrey}`}
							margin='15px auto 0 auto'
							onClick={handleLogin}>
							Ingresar
						</PrimaryButton>
					</StyledForm>
				</Box>
			)}
		</Container>
	);
};

export default Login;

const Container = styled.div`
	height: 100vh;
	padding: 2.5rem 0 0 0;
	background-color: ${theme.colors.primary};
	display: flex;
	align-items: center;
`;

const Box = styled.div`
	width: ${({ mobile }) => (mobile ? '91%' : '95%')};
	max-width: 1440px;
	margin: 0 auto 10% auto;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const StyledForm = styled.form`
	width: 30%;
	display: flex;
	flex-direction: column;
`;

const StyledLabel = styled.label`
	font-family: helvMedium;
	font-size: ${theme.fontSize.desktop.parrafo18};
	line-height: ${theme.lineHeigth.desktop.parrafo18};
	margin-bottom: 0.45rem;
	color: ${theme.colors.white};
`;
const InputContainer = styled.div`
	height: 100px;
`;
const StyledInput = styled.input`
	width: 100%;
	font-family: helvMedium;
	font-size: ${theme.fontSize.desktop.parrafo16};
	line-height: ${theme.lineHeigth.desktop.parrafo16};
	color: ${theme.colors.darkGrey};
	padding: 0.45rem 1rem;
	border-radius: 8px;
	border: 1px solid ${theme.colors.grey};
	:focus {
		outline: 0;
	}
`;

const ErrorMsg = styled.span`
	font-family: helvRegular;
	font-size: 0.875rem;
	line-height: 1.25rem;
	color: ${theme.colors.ligthGrey};
	width: 100%;
	height: 3rem;
`;
