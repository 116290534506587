import React, { useEffect, useMemo, useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';

import Loader from '../../Components/Shared/Loader';
import PrimaryButton from '../../Components/Shared/PrimaryButton';
import Select from 'react-select';
import StyledH1 from './../../Components/Shared/StyledH1/index';
import StyledParagraph16 from './../../Components/Shared/StyledParagraph16/index';
import { db } from '../../Firebase';
import { storage } from './../../Firebase/index';
import styled from 'styled-components';
import theme from '../../Constants/theme';
import { uid } from 'uid';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from './../../Hooks/index';

const NewElementContainer = ({ fieldsList, title }) => {
	const [blogData, setBlogData] = useState({});
	const [file, setFile] = useState(null);
	const [pending, setPending] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [supported, setSupported] = useState(true);
	const { height } = useWindowSize();
	const navigate = useNavigate();
	const blogId = uid();

	const handleChange = (name, value) => {
		setBlogData((current) => {
			return { ...current, [name]: value };
		});
	};

	const handleFile = (e) => {
		const type = e.target.files[0].type;

		if (type.includes('jpg') || type.includes('jpeg') || type.includes('png')) {
			setFile(e.target.files[0]);
			setSupported(true);
		} else {
			setSupported(false);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setPending(true);

		const name = `${file.name}`;
		const blogImgRef = ref(storage, `Blogs/${name}`);
		const date = Date.now();

		uploadBytes(blogImgRef, file)
			.then((snapshot) => {
				addDoc(collection(db, 'Blogs'), {
					...blogData,
					img: name,
					date,
					blogId,
				});
			})
			.finally(() => {
				setTimeout(() => {
					setPending(false);
					navigate('/admin/blogs');
				}, 1000);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	useEffect(() => {
		blogData.title !== '' &&
		blogData.title !== undefined &&
		blogData.body !== '' &&
		blogData.body !== undefined &&
		blogData.category !== '' &&
		blogData.category !== undefined &&
		supported &&
		file != null
			? setDisabled(false)
			: setDisabled(true);
	}, [blogData, file, supported]);

	const imgInput = useMemo(() => {
		return (
			<>
				<FileInput name='file' type='file' onChange={(e) => handleFile(e)} />
				<Preview background={supported && file && URL.createObjectURL(file)}>
					{!supported && (
						<StyledParagraph16 margin='10px 0 0 10px'>
							Tipo de archivo no soportado{' '}
						</StyledParagraph16>
					)}
				</Preview>
				<StyledParagraph16 margin='10px 0 0 0'>
					Extensiones de archivos permitidas: .JPG, .JPEG Y .PNG
				</StyledParagraph16>
			</>
		);
	}, [file, supported]);

	const getFieldsType = (type, name, options) => {
		switch (type) {
			case 'text':
				return (
					<TextInput onChange={(e) => handleChange(name, e.target.value)} />
				);
			case 'textArea':
				return (
					<TextAreaInput
						onChange={(e) => handleChange(name, e.target.value)}
						rows='5'
					/>
				);
			case 'img':
				return imgInput;
			case 'select':
				return (
					<Select
						name={name}
						placeholder='Elige una categoria'
						options={options}
						onChange={(e) => handleChange(name, e.value)}
					/>
				);
			default:
				return <TextInput />;
		}
	};

	return (
		<Container height={height}>
			<StyledH1>{title}</StyledH1>
			<FieldContainer>
				{fieldsList.map((e, i) => (
					<InputContainer key={i}>
						<StyledLabel>
							{e.inputLabel}
							{e.isRequired && <Required>* campo obligatorio</Required>}
						</StyledLabel>{' '}
						{getFieldsType(e.type, e.name, e.options)}
					</InputContainer>
				))}
			</FieldContainer>
			<PrimaryButton disabled={disabled} width='15%' onClick={handleSubmit}>
				{!pending ? (
					'Crear'
				) : (
					<Loader
						flex={true}
						marginVertical='0'
						margin='0'
						size='27'
						border='3'
						color={`${theme.colors.white}`}
					/>
				)}
			</PrimaryButton>
		</Container>
	);
};

export default NewElementContainer;

const Container = styled.div`
	height: ${({ height }) => height && `${height}px`};
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 5%;
	row-gap: 2rem;
`;

const FieldContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	overflow-y: scroll;
	height: 77%;
`;

const InputContainer = styled.div`
	width: 60%;
	margin: 0 0 2% 0;
	display: flex;
	flex-direction: column;
`;

const StyledLabel = styled.label`
	font-family: helvBlack;
	font-size: ${theme.fontSize.desktop.parrafo18};
	line-height: ${theme.lineHeigth.desktop.parrafo18};
	margin-bottom: 0.45rem;
	color: ${theme.colors.darkGrey};
	display: flex;
	align-items: center;
`;

const TextInput = styled.input`
	width: 100%;
	font-family: helvMedium;
	font-size: ${theme.fontSize.desktop.parrafo16};
	line-height: ${theme.lineHeigth.desktop.parrafo16};
	color: ${theme.colors.darkGrey};
	padding: 0.45rem 1rem;
	border-radius: 8px;
	border: 1px solid ${theme.colors.grey};
	:focus {
		outline: 0;
	}
`;

const TextAreaInput = styled.textarea`
	width: 100%;
	font-family: helvMedium;
	font-size: ${theme.fontSize.desktop.parrafo16};
	line-height: ${theme.lineHeigth.desktop.parrafo16};
	color: ${theme.colors.darkGrey};
	padding: 0.45rem 1rem;
	border-radius: 8px;
	border: 1px solid ${theme.colors.grey};
	:focus {
		outline: 0;
	}
`;

const Required = styled.span`
	font-size: 0.75rem;
	color: ${theme.colors.primary};
	margin: 0 0 0 0.35rem;
`;

const FileInput = styled.input``;

const Preview = styled.div`
	width: 300px;
	height: 200px;
	margin: 1rem 0 0 0;
	${({ background }) =>
		!background && `background-color: ${theme.colors.ligthGrey} `};
	${({ background }) => background && `background-image: url('${background}')`};
	${({ background }) => background && 'background-size: cover'};
	${({ background }) => background && 'background-position: center center'};
`;
