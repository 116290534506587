import DownloadCatalogo from './../../Components/DownloadCatalogo/index';
import { Helmet } from 'react-helmet';
import MarcasExclusivas from './../../Components/MarcasExclusivas/index';
import React from 'react';
import WhatsappIcon from './../../Components/Shared/WhatsappIcon/index';
import styled from 'styled-components';
import { useIsMobile } from '../../Hooks/index';

const NuestrasMarcasContainer = () => {
	const mobile = useIsMobile();

	return (
		<>
			<Helmet>
				<title>Nuestras marcas</title>
				<link
					rel='canonical'
					href='http://simpleabasto.com.ar/nuestras-marcas'
				/>
				<meta
					name='description'
					content='Desde el año 2014, brindamos desarrollo, representación y comercialización de marcas de retail, basados en el servicio y la satisfacción del cliente. Nos proponemos simplificar tus compras y unificar tus proveedores. Trabajamos con stock permanente, lo que nos permite dar una rápida y dinámica respuesta a tus necesidades. Venimos a hacerte la vida más Simple.'
				/>
				<meta
					name='keywords'
					content='representación, comercialización, retail, simple,abasto, distribución,bares, restaurant, restauranes, abasto gastronómico, insumos,insumos bares, pedidos bares, compras, cocinas, swift, hellmans,tregar, canal tradicional, canal gastronómico,  quento, córdoba, argentina'
				/>
			</Helmet>
			<Container mobile={mobile}>
				<WhatsappIcon />
				<MarcasExclusivas home={false} />
				<DownloadCatalogo />
			</Container>
		</>
	);
};

export default NuestrasMarcasContainer;

const Container = styled.div`
	width: ${({ mobile }) => (mobile ? '100%' : '95%')};
	max-width: 1440px;
	margin: 0 auto 0 auto;
	padding: ${({ mobile }) => (mobile ? '8.5rem 0 0 0' : '11rem 0 0 0')};
`;
