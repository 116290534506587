import React, { useMemo } from 'react';

import styled from 'styled-components';
import theme from './../../../Constants/theme';
import { useIsMobile } from '../../../Hooks';
import { useWindowSize } from './../../../Hooks/index';

const WhatsappIcon = ({ link }) => {
	const mobile = useIsMobile();
	const { width } = useWindowSize();
	const marginLeft = width < 1600 ? '85%' : '1360px';
	const windowHeight = useWindowSize().height;

	const CTA = link
		? link
		: 'https://api.whatsapp.com/send?phone=5493518170151&text=%C2%A1Hola!%20Necesito%20m%C3%A1s%20informaci%C3%B3n%20acerca%20de%20sus%20productos%20y%20precios.';

	const icon = useMemo(
		() => (
			<Link
				href={CTA}
				mobile={mobile}
				height={windowHeight}
				marginLeft={marginLeft}>
				<Container mobile={mobile}>
					<img
						src='https://firebasestorage.googleapis.com/v0/b/simple-abasto.appspot.com/o/imagenesWeb%2Fwp.png?alt=media&token=ae39864a-65dd-48d3-8497-999ea446c4f3'
						alt='Botón whatsapp'
					/>
				</Container>
			</Link>
		),
		[CTA, mobile, marginLeft, windowHeight]
	);

	return icon;
};

export default WhatsappIcon;
const Link = styled.a`
	position: fixed;
	z-index: ${theme.zIndex.wpIcon};
	top: ${({ mobile, height }) => (mobile ? '75vh' : `${height * 0.65}px`)};
	margin-left: ${({ mobile, marginLeft }) =>
		mobile ? '82%' : `${marginLeft}`};
`;

const Container = styled.div`
	cursor: pointer;
	border-radius: 74px;
	z-index: ${theme.zIndex.wpIcon};

	img {
		width: ${({ mobile }) => (mobile ? '60px' : '74px')};
		height: ${({ mobile }) => (mobile ? '60px' : '74px')};
	}

	:hover {
		${({ mobile }) =>
			!mobile && `background-color: ${theme.colors.whatsappBackground}`};
	}
`;
